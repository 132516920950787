<template>
    <div>
        <el-container>
            <el-main>
                <h2>
                    <span v-if="address">{{address}}</span>
                    <span v-else>正在定位当前位置</span>
                    <i class="el-icon-position"></i>
                </h2>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    export default {
        data() {
            return {
            };
        },
        created() {
            this.getMyLocation()

            //定位获得当前位置信息
        },
        methods: {
            // getMyLocation() {
            //     var geolocation = new window.qq.maps.Geolocation('UKIBZ-PXDLA-I7HKC-C3N55-LANOE-ZGFR2', 'myapp')
            //     geolocation.getLocation(this.showPosition, this.showErr)
            // },
            getMyLocation() {
                var geolocation = new window.qq.maps.Geolocation("UKIBZ-PXDLA-I7HKC-C3N55-LANOE-ZGFR2", "myapp");
                geolocation.getIpLocation(this.showPosition, this.showErr);
                // geolocation.getLocation(this.showPosition, this.showErr);//或者用getLocation精确度比较高
            },
            showPosition(position) {
                alert(JSON.stringify(position))//打印位置信息

            },
            showErr(res) {
                alert("定位失败,请重试");
            }
        }

    };
</script>
