<template>
    <div>
<!--        面包屑导航区域-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>权限管理</el-breadcrumb-item>
            <el-breadcrumb-item>角色列表</el-breadcrumb-item>
        </el-breadcrumb>

<!--        卡片视图-->
        <el-card>
<!--            添加角色按钮区域 el-row是一行   el-col是一列，把按钮放在列里面-->
            <el-row>
                <el-col>
                    <el-button type="primary" @click="addDialogVisible = true">添加角色</el-button>
                </el-col>
            </el-row>
<!--            角色列表区域-->
            <el-table :data="rolelist" border stripe>
<!--                展开列-->
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <!-- el-row el-col 实现栅格布局 -->
                        <el-row :class="['bdbottom', index1 === 0 ? 'bdtop' : '']"
                                v-for="(item1,index1) in scope.row.children" :key="item1.id">
                            <!-- 渲染一级权限 -->
                            <el-col :span="5">
                                <el-tag closable @close="removeRightById(scope.row,item1.id)">{{item1.authName}}</el-tag>
                                <i class="el-icon-caret-right"></i>
                            </el-col>
                            <!-- 渲染二级和三级权限 -->
                            <el-col :span="19">
                                <!-- 通过for循环，嵌套渲染二级权限 -->
                                <el-row :class="[index2 === 0 ? '' : 'bdtop', 'vcenter']"
                                        v-for="(item2,index2) in item1.children" :key="item2.id">
                                    <el-col :span="6">
                                        <el-tag type="success" closable @close="removeRightById(scope.row,item2.id)">{{item2.authName}}</el-tag>
                                        <i class="el-icon-caret-right"></i>
                                    </el-col>
                                    <el-col :span="18">
                                        <el-tag type="warning" v-for="item3 in item2.children" :key="item3.id" closable @close="removeRightById(scope.row,item3.id)">{{ item3.authName }}</el-tag>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </template>
                </el-table-column
<!--                索引列-->
                <el-table-column type="index"></el-table-column>
                <el-table-column label="角色名称" prop="roleName"></el-table-column>
                <el-table-column label="角色描述" prop="roleDesc"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" icon="el-icon-edit" @click="showEditDialog(scope.row.id)">编辑</el-button>
                        <el-button size="mini" type="danger" icon="el-icon-delete" @click="removeRolesById(scope.row.id)">删除</el-button>
                        <el-button size="mini" type="warning" icon="el-icon-setting">分配角色</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <!-- 1. 添加角色的对话框 -->
        <!-- visible控制对话框的显示隐藏 -->
        <el-dialog title="添加角色" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
            <!-- 内容主体区域 -->
            <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px">
                <el-form-item label="角色名称" prop="roleName">
                    <el-input v-model="addForm.roleName"></el-input>
                </el-form-item>
                <el-form-item label="角色描述" prop="roleDesc">
                    <el-input v-model="addForm.roleDesc"></el-input>
                </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addRole">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 2. 编辑角色对话框 -->
        <el-dialog title="修改角色" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
            <!-- 内容主体区域 -->
            <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="80px">
                <el-form-item label="角色名称" prop="roleName">
                    <el-input v-model="editForm.roleName" ></el-input>
                </el-form-item>
                <el-form-item label="角色描述" prop="roleDesc">
                    <el-input v-model="editForm.roleDesc"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible=false">取 消</el-button>
                <el-button type="primary" @click="editRoleInfo">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Roles",
        data(){
            return{
                //所有角色列表数据
                rolelist:[
                    {
                        id:1,
                        roleDesc:'技术负责人',
                        roleName:'主管',
                        children:[
                            {
                                id:2,
                                authName:'订单管理',
                                children:[
                                    {id: 11, authName: '新建订单', path: 'createOrder', children: []},
                                    {id: 12, authName: '订单列表', path: 'orders', children: []},
                                    {id: 13, authName: '售后管理', path: 'afterSales', children: []}
                                ]
                            },
                            {
                                id:6,
                                authName:'人员管理',
                                children:[
                                    {id: 61, authName: '用户列表', path: 'users', children: []}
                                ]
                            }
                        ]
                    },
                    {
                        id:2,
                        roleDesc:'测试角色描述',
                        roleName:'测试角色',
                        children:[
                            {
                                id:7,
                                authName:'权限管理',
                                children:[
                                    {id: 71, authName: '权限列表', path: 'rights', children: []},
                                    {id: 72, authName: '角色列表', path: 'roles', children: []}
                                ]
                            }
                        ]
                    }
                ],
                //控制添加角色对话框的显示与隐藏
                addDialogVisible: false,
                // 1.添加角色的表单数据
                addForm: {
                    roleName: '',
                    roleDesc: '',
                },
                // 1.添加角色的验证规则对象
                addFormRules: {
                    roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
                },
                // 2. 控制编辑角色对话框的显示与隐藏
                editDialogVisible: false,
                //查询到的角色对象
                editForm: {},
                editFormRules: {
                    roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
                }
            }
        },
        created(){
            this.getRolesList()
        },
        methods:{
            //获取所有角色的列表
            async getRolesList(){
                const {data:res} = await this.$http.get('roles');
                if(res.meta.status !== 200){
                    return this.$message.error('获取角色列表失败');
                }
                this.rolelist=res.data;
                //打印结果如上图，返回一个Array(6)
                console.log(this.rolelist)
            },
            // 1.监听添加用户对话框的关闭事件
            addDialogClosed() {
                this.$refs.addFormRef.resetFields()
            },
            //1.点击确定按钮，添加新用户
            addRole() {
                this.$refs.addFormRef.validate(async (valid) => {
                    //valid会返回一个布尔值，如果true就表示校验通过，反之false
                    //validate是element-ui表单的一个验证方法
                    if (!valid) return //失败了，直接return

                    //验证成功，发起添加用户的网络请求
                    const { data: res } = await this.$http.post('roles', this.addForm);
                    if (res.meta.status != 201) {
                        this.$message.error('添加角色失败');
                    }
                    this.$message.success('添加角色成功');
                    //隐藏添加用户的对话框
                    this.addDialogVisible = false;
                    //重新获取用户列表数据
                    this.getRolesList();
                })
            },
            // 展示编辑角色的对话框
            async showEditDialog(id) {
                this.editDialogVisible = true;
                const { data: res } = await this.$http.get('roles/' + id);
                if (res.meta.status !== 200) {
                    return this.$message('查询角色信息失败！');
                }
                //查询到的数据保存到editForm 上
                this.editForm = res.data;
            },
            editDialogClosed() {
                this.$refs.editFormRef.resetFields()
            },
            editRoleInfo() {
                this.$refs.editFormRef.validate(async (valid) => {
                    if (!valid) return;
                    const { data: res } = await this.$http.put('roles/' + this.editForm.roleId, {
                        roleName: this.editForm.roleName,
                        roleDesc: this.editForm.roleDesc,
                    });
                    if (res.meta.status !== 200) {
                        return this.$message.error('更新角色信息失败！');
                    }
                    this.editDialogVisible = false;
                    this.getRolesList();
                    this.$message.success('更新角色信息成功！');
                })
            },
            async removeRolesById(id) {
                const confirmResult = await this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).catch((error) => error);
                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消删除');
                }
                const {data:res} = await this.$http.delete('roles/' + id);
                if(res.meta.status !==200){
                    return this.$message.error('删除角色失败！');
                }
                this.$message.success('删除角色成功！');
                this.getRolesList();
            },
            //根据id删除对应的权限
            async removeRightById(role, rightId){
                //弹框提示是否删除
                const confirmResult = await this.$confirm('此操作将永久删除该权限, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).catch((error) => error);
                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消删除');
                }
                const { data: res } = await this.$http.delete(`roles/${role.id}/rights/${rightId}`);
                if (res.meta.status !== 200) {
                    return this.$message.error('删除权限失败！');
                }
                this.$message.success('删除权限成功！');
                //把服务器返回的最新权限直接赋值给当前角色的children属性， 可以防止整个列表刷新，提升用户体验
                role.children = res.data
            }
        }
    }
</script>

<style scoped>
    .el-tag {
        margin: 7px;
    }
    .bdtop {
        border-top: 1px solid #eee;
    }
    .bdbottom {
        border-bottom: 1px solid #eee;
    }
    .vcenter {
        display: flex;
        align-items: center;
    }
</style>