<template>
    <div>
        <!--    面包屑导航区域-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>用户管理</el-breadcrumb-item>
            <el-breadcrumb-item>用户列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--        Users.vue-->
        <!--        卡片视图-->
        <el-card>
            <!--            搜索与添加区域-->
            <!--            el-row el-col 实现栅格布局-->
            <!--            Row 组件 提供 gutter 属性来指定每一栏之间的间隔，默认间隔为 0-->
            <el-row :gutter="30">
                <el-col :span="10">
                    <el-input placeholder="请输入搜索的内容" v-model="queryInfo.query" clearable @clear="getUserList">
                        <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addDialogVisible = true">添加用户</el-button>
                </el-col>
            </el-row>
        </el-card>
        <!--        Users.vue-->
        <!--         用户列表区域-->
        <!--         默认情况下，Table 组件是不具有竖直方向的边框的，如果需要，可以使用border属性，它接受一个Boolean，设置为true即可启用。-->
        <!--        stripe属性可以创建带斑马纹的表格。它接受一个Boolean，默认为false，设置为true即为启用-->
        <!--        :data绑定数据源，也就是请求返回的uerslist-->
        <el-table :data="userlist" border stripe>
            <el-table-column type='index' label=""></el-table-column><!--            索引列-->
            <!--            label指定当前列标题  prop指定当前这一列指向哪个数据-->
            <el-table-column prop="username" label="姓名"></el-table-column>
            <el-table-column prop="email" label="邮箱"></el-table-column>
            <el-table-column prop="mobile" label="电话"></el-table-column>
            <el-table-column prop="role_name" label="角色"></el-table-column>
            <el-table-column prop="mg_state" label="状态">
                <!--                 slot-scope已经弃用了，可以用 v-slot="scope"-->
                <!--                 插槽 slot-scope 固定写法 拿到数据源中当前行的数据-->
                <!--                作用域插槽 会覆盖prop-->
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.mg_state" @change="userStateChanged(scope.row)">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
<!--                    修改按钮-->
                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
                    <!--                    删除按钮-->
                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeUserById(scope.row.id)"></el-button>
                    <!--                    分配角色按钮-->
                    <el-tooltip effect="dark" content="分配角色" placement="top" :enterable="false">
                        <el-button type="warning" icon="el-icon-setting" size="mini" @click="setRole(scope.row)"></el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.page"
                :page-sizes="[1, 2, 5, 10]"
                :page-size="queryInfo.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
        <!-- 添加用户的对话框 -->
        <!--                visible控制对话框的显示隐藏-->
        <el-dialog title="添加用户" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
            <!--                    内容主体区域-->
            <!--                    内容主体区域-->
            <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="70px">
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="addForm.username"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="addForm.password"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="addForm.email"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input v-model="addForm.mobile"></el-input>
                </el-form-item>
            </el-form>
            <!--                    底部区域-->
            <span slot="footer" class="dialog-footer">
                        <el-button @click="addDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="addUser">确 定</el-button>
                    </span>
        </el-dialog>
        <!-- 修改用户对话框 -->
        <el-dialog title="修改用户" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
<!--            editForm保存了数据，双向数据绑定之后就可以打开界面默认显示-->
<!--            ：rules是验证规则对象-->
<!--            ：ref当前表单的引用对象-->
            <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="70px">
                <el-form-item label="用户名" prop="username">
<!--                    disabled表示禁用，不用校验不要prop-->
                    <el-input v-model="editForm.username" disabled></el-input>
                </el-form-item>
<!--                prop表示校验规则-->
                <el-form-item label="邮箱" prop="email" >
                    <el-input v-model="editForm.email" ></el-input>
                </el-form-item>
                <el-form-item label="手机" prop="mobile" >
                    <el-input v-model="editForm.mobile" ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible=false">取 消</el-button>
                <el-button type="primary" @click="editUserInfo">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 分配角色的对话框 -->
        <el-dialog title="提示" :visible.sync="setRoleDialogVisible" width="50%" @close="setRoleDialogClosed">
            <div>
                <p>当前用户：{{userInfo.username}}</p>
                <p>当前角色：{{userInfo.role_name}}</p>
                <p>分配新角色：
                    <el-select v-model="selectedRoleId" placeholder="请选择">
                        <el-option v-for="item in rolesList" :key="item.id" :label="item.roleName" :value="item.id">
                        </el-option>
                    </el-select>
                </p>
            </div>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="setRoleDialogVisible = false">取 消</el-button>
                <el-button type="primary"  @click="saveRoleInfo">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Users",
        data() {
            //验证邮箱的规则
            var checkEmail = (rule, value, callback) => {
                //验证邮箱的正则表达式
                const regEmail = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
                // test() 方法是正则表达式的一个方法，用于检测一个字符串是否匹配某个模式
                //调用test来检验这个value是否合法
                if (regEmail.test(value)) {
                    //合法的邮箱
                    return callback()
                }
                callback(new Error('请输入合法的邮箱'))
            };
            //验证手机号的规则
            var checkMobile = (rule, value, callback) => {
                const regMobile = /^(?:(?:\+|00)86)?1\d{10}$/;
                if (regMobile.test(value)) {
                    return callback()
                }
                callback(new Error('请输入合法的手机号'))
            };
            return {
                //获取用户列表的参数对象
                //建议把get参数都定义在data数据中
                queryInfo: {
                    query: '',
                    page: 1, //当前页数
                    pagesize: 10  //当前每页显示多少条数据
                },
                userlist: [
                    {
                        "id": 25,
                        "username": "tige117",
                        "mobile": "18616358651",
                        "type": 1,
                        "email": "tige112@163.com",
                        "create_time": "2017-11-09T20:36:26.000Z",
                        "mg_state": true, // 当前用户的状态
                        "role_name": "炒鸡管理员"
                    }

                ],
                total: 1, //总数据条数
                //控制添加用户对话框的显示与隐藏
                addDialogVisible:false,
                // 添加用户的表单数据
                //控制修改用户对话框的显示与隐藏
                editDialogVisible:false,
                // 查询到的用户的信息对象
                editForm: {},
                addForm: {
                    username: '',
                    password: '',
                    email: '',
                    mobile: ''
                },
                // 添加表单的验证规则对象
                addFormRules: {
                    username: [
                        { required: true, message: '请输入用户名', trigger: 'blur' },
                        { min: 3, max: 10, message: '用户名的长度在3-10个字符之间', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { min: 6, max: 15, message: '密码的长度在6-15个字符之间', trigger: 'blur' }
                    ],
                    email: [
                        { required: true, message: '请输入邮箱', trigger: 'blur' },
                        { validator: checkEmail, trigger: 'blur' },
                    ],
                    mobile: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                        { validator: checkMobile, trigger: 'blur' },
                    ]
                },
                editFormRules:{
                    email: [
                        { required: true, message: '请输入邮箱', trigger: 'blur' },
                        { validator: checkEmail, trigger: 'blur' },
                    ],
                    mobile: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                        { validator: checkMobile, trigger: 'blur' },
                    ],
                },
                //控制分配角色的显示与隐藏
                setRoleDialogVisible: false,
                //需要被分配角色的用户信息
                userInfo: {},
                //所有角色的数据列表
                rolesList: [
                    {
                        id:30,
                        roleName:'主管',
                        roleDsec:'技术负责人',
                        children:[
                            {
                                id:101,
                                authName:'商品管理',
                                path:null,
                                children:[
                                    {
                                        id:104,
                                        authName:'商品列表',
                                        path:null,
                                        children:[
                                            {
                                                id:105,
                                                authName:'添加商品',
                                                path:null
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ],
                // 已选中的角色id值
                selectedRoleId: ''
            }
        },
        created() {
            //在created生命周期函数中发起请求
            //页面创建期间就会调用getUserList函数，发起get请求
            // this.getUserList()
        },
        methods: {
            async getUserList() {
                const {data: res} = await this.$http.get('users', {queryInfo: this.queryInfo, page: this.page});
                if (res.meta.status != 200) {
                    return this.$message.error('获取用户列表失败')
                }
                this.userlist = res.data.users;
                this.total = res.data.total;
                console.log(res) //打印结果见下图
            },
            // 添加两个事件的事件处理函数 @size-change，@current-change
//监听 pagesize 改变的事件
            handleSizeChange(newSize) {
                this.queryInfo.pagesize = newSize;//监听到的值保存到queryInfo
                this.getUserList()//根据最新的newSize，重新发起请求
            },
            //监听页码值改变的事件
            handleCurrentChange(newPage) {
                this.queryInfo.page = newPage;
                this.getUserList()
            },
            // 监听switch开关状态的改变
            async userStateChanged(userinfo) {
                // 发送请求进行状态修改
                // 用模板字符串比较方便
                const {data: result} = await this.$http.put(`users/${userinfo.id}/state/${userinfo.mg_state}`);
                //如果失败，取反可以保证页面状态没有变化
                if (result.meta.status !== 200) {
                    userinfo.mg_state = !userinfo.mg_state;
                    return this.$message.error('更新用户状态失败');
                }
                this.$message.success('更新用户状态成功');
            },
            // 监听添加用户对话框的关闭事件
            addDialogClosed() {
                this.$refs.addFormRef.resetFields();
            },
            // 监听修改用户对话框的关闭事件
            editDialogClosed() {
                // resetFields对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
                this.$refs.editFormRef.resetFields()
            },
            //点击按钮，添加新用户
            addUser(){
                this.$refs.addFormRef.validate( async (valid) => {
                    //valid会返回一个布尔值，如果true就表示校验通过，反之false
                    //validate是element-ui表单的一个验证方法
                    if (!valid) return; //失败了，直接return

                    //验证成功，发起添加用户的网络请求
                    const {data:res} = await this.$http.post('users',this.addForm);
                    if(res.meta.status != 201){
                        this.$message.error('添加用户失败');
                    }
                    this.$message.success('添加用户成功');
                    //隐藏添加用户的对话框
                    this.addDialogVisible = false;
                    //重新获取用户列表数据
                    this.getUserList();
                })
            },
            // 展示编辑用户的对话框
            // 2.接收id
            async showEditDialog(id){
                this.editDialogVisible = true;
                const {data: result} = await this.$http.get('users/' + id);
                if(result.meta.status !== 200) {
                    return this.$message.error('查询用户信息失败！')
                }
                //3.查询到的数据保存到editForm 上
                this.editForm = result.data;
            },
            //修改用户信息并提交
            editUserInfo(){
                this.$refs.editFormRef.validate(async (valid)=> {
                    if(!valid) return; //验证失败
                    //验证成功：发起修改用户信息的put请求
                    const {data:res} = await this.$http.put('users/' + this.editForm.id,
                        {
                            email:this.editForm.email,
                            mobile:this.editForm.mobile
                        });
                    if(res.meta.status !==200) {
                        return this.$message.error('更新用户信息失败！');
                    }
                    //修改成功
                    //1.关闭对话框
                    this.editDialogVisible=false;
                    //2.刷新数据列表
                    this.getUserList();
                    //3.提示修改成功
                    this.$message.success('更新用户信息成功！');
                })
            },
            //根据id删除对应用户信息
            async removeUserById(id) {
                //弹窗询问是否删除
                //如果用户确定删除 confirmResult 返回字符串 confirm
                //如果用户取消删除 confirmResult 返回字符串 cancel
                const confirmResult = await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).catch(error => error);

                if(confirmResult !=='confirm'){
                    return this.$message.info('已取消删除');
                }
                const {data:res} = await this.$http.delete('users/' + id);
                if(res.meta.status !==200){
                    return this.$message.error('删除用户失败！');
                }
                this.$message.success('删除用户成功！');
                this.getUserList();
            },
            //展示分配角色的对话框
            async setRole(userInfo) {
                this.userInfo = userInfo
                //展示对话框之前，获取所有角色列表
                // const { data: res } = await this.$http.get('roles')
                // if (res.meta.status !== 200) {
                //     return this.$message.error('获取角色列表失败！')
                // }
                // this.$message.success('获取角色列表成功！')
                // this.rolesList = res.data

                this.setRoleDialogVisible = true;
            },
            //点击按钮分配角色
            async saveRoleInfo(){
                if(!this.selectedRoleId){
                    return this.$message.error('请选择要分配的角色');
                }
                const {data:res} = await this.$http.put(`users/${this.userInfo.id}/role`,{rid: this.selectedRoleId});
                if(res.meta.status!==200){
                    return this.$message.error('更新角色失败！');
                }
                this.$message.success('更新角色成功！');
                this.getUserList();
                this.setRoleDialogVisible=false;
            },
            //监听分配角色对话框的关闭事件
            setRoleDialogClosed(){
                this.selectedRoleId ='';
                this.userInfo = {};
            }
        }
    }
</script>

<style scoped>

</style>