<template>
    <div>
        <!-- 面包屑导航区域 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>核销订单</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 卡片视图区域 -->
        <el-card>
            <el-row :gutter="30">
                <el-col :span="9">
                    <el-input v-model="queryInfo.outerId" placeholder="请输入订单号">
                        <el-button slot="append" icon="el-icon-search" @click="serach()"></el-button>
                    </el-input>
                </el-col>
            </el-row>
        </el-card>
        <!-- 订单列表数据 -->
        <el-table :data="consumeList" style="width: 100%;padding:24px;" stripe>
            <el-table-column label="订单号" prop="outerId" align="center">
            </el-table-column>
            <el-table-column label="核销码" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.ordernew">【{{scope.row.ordernew.code}}】</div>
                </template>
            </el-table-column>
            <el-table-column label="商品【规格】" v-html align="center">
                <template slot-scope="scope">
                    <span v-html="scope.row.itemTitle"></span>
                    <div>【{{scope.row.skuProperties}}】</div>
                </template>
            </el-table-column>
            <el-table-column label="数量" prop="amount" align="center">
            </el-table-column>
            <el-table-column label="核销时间" prop="consume_time" align="center">
            </el-table-column>
            <el-table-column label="佣金（￥）" align="center">
                <template slot-scope="scope">
                    【{{scope.row.commission}}】
                </template>
            </el-table-column>
            <el-table-column label="订单状态" prop="status" align="center">
                <template slot-scope="scope" >
                    <el-tag type="warning" style="margin-right:5px;" v-if="scope.row.status === 0">待核销</el-tag>
                    <el-tag type="primary" style="margin-right:5px;"v-else-if="scope.row.status === 1">已核销</el-tag>
                    <el-tag type="danger" style="margin-right:5px;" v-else-if="scope.row.status === 2">已退款</el-tag>
                    <el-tag type="warning" v-if="scope.row.commission_status === 0">待分佣</el-tag>
                    <el-tag type="primary" v-else-if="scope.row.commission_status === 1">已分佣</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.commission_status === 2">分佣失败</el-tag>
                    <div v-if="scope.row.commission_status==2" style="margin-top: 5px;">【失败原因：{{scope.row.commission_error_reason}}】</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="350px">
                <template slot-scope="scope" >
                    <el-button icon="el-icon-search" size="mini" type="success" @click="showConsumeDialog(scope.row.id,scope.row.merchid,scope.row.commission,1)">门店</el-button>
                    <el-button v-if="scope.row.commission_status=='0'" icon="el-icon-edit" size="mini" type="primary" @click="showConsumeDialog(scope.row.id,scope.row.merchid,scope.row.commission,2)">佣金</el-button>
                    <el-button v-if="scope.row.status=='1' && scope.row.commission_status!='1'" icon="el-icon-money" size="mini" type="warning" @click="widthdraw(scope.row.id)">提现</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="queryInfo.page" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pagesize"
                       layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <!-- 修改修改地址的对话框 -->
        <el-dialog title="查看门店/修改佣金" :visible.sync="consumeVisible" width="50%" @close="setConsumeDialogClosed">
            <!-- 内容主体区域 -->
            <el-form  ref="consumeFormRef" label-width="150px">
                <el-form-item label="核销门店：" label-width="150px" v-if="show_type==1">
                    <div>
                        <el-descriptions direction="vertical" :column="2" border>
                            <el-descriptions-item label="门店【id】">{{chooseMerch.merchname}}【{{chooseMerch.merchid}}】</el-descriptions-item>
                            <el-descriptions-item label="电话">{{chooseMerch.mobile}}</el-descriptions-item>
                            <el-descriptions-item label="地址">{{chooseMerch.address}}</el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form-item>
                <el-form-item label="佣金：" v-if="show_type==2">
                    <div v-if="show_type==1">{{commission}}</div>
                    <el-input v-else v-model="commission"></el-input>
                </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer" v-if="show_type==2">
                <el-button @click="consumeVisible = false">取 消</el-button>
                <el-button type="primary" @click="updataCommission()">修 改</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "consumeList",
        data() {
            return {
                // 核销订单id
                id:'',
                queryInfo: {
                    query: '',
                    page: 1,
                    pagesize: 10,
                    outerId:''
                },
                //可选择的一页多少条
                pageSizes: [],
                total: 1,
                consumeList: [],
                consumeVisible: false,
                imgHost:'https://cdn.naoled.cn/',
                consume:[],
                //佣金
                commission:'',
                chooseMerch:{
                    //选中的门店名
                    merchname:'',
                    //选中的门店ID
                    merchid:'',
                    //选中的门店联系方式
                    mobile:'',
                    //选中的门店地址
                    address:'',
                    //选中省市区
                    province:'',
                    city:'',
                    area:''
                },
                //查看门店或修改佣金的类型
                show_type:''
            }
        },
        created() {
            this.getConsumeList()
        },
        methods: {
            async getConsumeList() {
                const {data: result} = await this.$http.get('consume', {
                    params: this.queryInfo
                }).catch((fail)=>{
                    return this.$message.error(fail.response.data.message);
                });
                if (result.status !== 0) {
                    return this.$message.error('获取数据列表失败');
                }
                this.total=result.result.total;
                this.consumeList = result.result.data;
                this.queryInfo.pagesize=result.result.per_page;
            },
            handleSizeChange(newSize) {
                this.queryInfo.pagesize = newSize;
                this.getConsumeList();
            },
            handleCurrentChange(newPage) {
                this.queryInfo.page = newPage;
                this.getConsumeList();
            },
            // 展示选择门店的对话框
            showConsumeDialog(id,merchid,commission,type) {
                this.show_type=type;
                this.id=id;
                this.commission=commission;
                this.getMerchList(merchid);
                this.consumeVisible = true;
            },
            setConsumeDialogClosed() {
                this.consumeVisible = false;
                this.chooseMerch.province='';
            },
            //获取门店列表
            async getMerchList(id) {
                if(id){
                    const {data: result} = await this.$http.get( 'ewei_shop_merch/'+id, {
                    }).catch((fail)=>{
                        return this.$message.error(fail.response.data.message);
                    });
                    if (result.status !== 0) {
                        return this.$message.error('获取门店列表失败');
                    }
                    this.chooseMerch.merchname=result.result.merchname;
                    this.chooseMerch.merchid=result.result.id;
                    this.chooseMerch.mobile=result.result.mobile;
                    this.chooseMerch.address=result.result.address;
                }else{
                    return this.$message.error('缺少参数： 门店id');
                }
            },
            async updataCommission(){
                let id=this.id;
                let commission=this.commission;
                const {data: result} = await this.$http.put('consume/'+id, {
                    commission:commission
                }).catch((fail)=>{
                    return this.$message.error(fail.response.data.message);
                });
                if (result.status !== 0) {
                    this.consumeVisible=false;
                    return this.$message.error('修改门店佣金失败');
                }
                this.consumeVisible=false;
                setTimeout(() => {
                    this.$router.go(0);
                },500);
                return this.$message.success('修改门店佣金成功');
            },
            async widthdraw(id){
                const confirmResult = await this.$confirm('确定手动提现吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).catch((error) => error);
                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消提现');
                }
                const {data: result} = await this.$http.put('consume/withdraw/'+id, {
                }).catch((fail)=>{
                    if(fail.response.data.result){
                        return this.$message.error(fail.response.data.result);
                    }else{
                        return this.$message.error(fail.response.data.message);
                    }
                });
                if (result.status !== 0) {
                    return this.$message.error('提现失败');
                }
                setTimeout(() => {
                    this.$router.go(0);
                },500);
                return this.$message.success('提现成功');
            },
            //查找订单
            async serach(){
                this.getConsumeList();
            },
        },
        computed:{

        }

    }
</script>

<style scoped>
    .bdtop {
        border-top: 1px solid #eee;
    }
    .bdbottom {
        border-bottom: 1px solid #eee;
    }
    .img_goods{
        width: 90%;
    }
    .title_goods{
        color: #409EFF;
        padding: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 40px;
    }
</style>