<template>
    <div>
        <el-container>
            <el-header class="el-header-fixed center" style="background-color: #409EFF;height:3.5rem;">
                <span v-if="tmallData.code" class="title">预约安装</span>
                <span v-else class="title">预约信息</span>
            </el-header>
            <el-main>
                <!--        卡片视图-->
                <el-card>
                    <el-col :span="24">
                        <el-form label-width="80px">
                            <el-form-item label="订单编号：" label-width="30%">
                                {{tmallData.itemId}}
                            </el-form-item>
                            <el-form-item label="商品名称：" label-width="30%">
                                <div v-html="tmallData.itemTitle"></div>
                            </el-form-item>
                            <el-form-item label="产品规格：" label-width="30%">
                                {{tmallData.skuProperties}}
                            </el-form-item>

                            <el-form-item v-if="tmallData.code" label="安装区域：" class="region" label-width="30%">
                                <el-col :span="24">
                                    <el-cascader :options="addressData" :disabled="false" @change="handleAddress" size="mini"
                                                 v-model="addressSelections" placeholder="请选择省市区" ref="cascader" popper-class="myascader"></el-cascader>
                                </el-col>
                            </el-form-item>
                            <el-form-item label="安装门店：" label-width="30%">
                                <el-col :span="24" v-if="tmallData.code" align="left" style="margin-bottom: 10px;">
                                    <el-button @click="showMerchList()" type="primary" size="small">选择门店</el-button>
                                    <div v-if="total=='0' && chooseMerch.province" style="color:red;font-size: .5rem;">*当前区域无可选门店，建议更换区域</div>
                                </el-col>
                                <div v-if="tmallData.merchid || chooseMerch.merchid" >
                                    <el-descriptions direction="vertical" :column="2" border>
                                        <el-descriptions-item label="门店">{{chooseMerch.merchname}}</el-descriptions-item>
                                        <el-descriptions-item label="电话">{{chooseMerch.contact_phone}}</el-descriptions-item>
                                        <el-descriptions-item label="地址">{{chooseMerch.address}}</el-descriptions-item>
                                    </el-descriptions>
                                </div>
                            </el-form-item>
                            <el-form-item label="品牌车型：" label-width="30%">
                                <el-col :span="24">
                                    <el-cascader v-if="tmallData.code"  placeholder="请输入车型" @change="carsSelect" :options="carsOption" popper-class="mycarsData" filterable size="mini"></el-cascader>
                                    <div v-else>{{tmallData.brand_name}} {{tmallData.car_name}}</div>
                                </el-col>
                            </el-form-item>
<!--                            <el-form-item label="预约时间：" label-width="30%">-->
<!--                                <el-row v-if="tmallData.code">-->
<!--                                    <el-col :span="24">-->
<!--                                        <el-date-picker-->
<!--                                                popper-class="mydatapicker"-->
<!--                                                class="elDatePicker"-->
<!--                                                :picker-options="expireTimeOPtion"-->
<!--                                                clearable-->
<!--                                                size="small"-->
<!--                                                v-model="expect_time"-->
<!--                                                value-format="yyyy-MM-dd HH:00:00"-->
<!--                                                :default-value="dateDefaultShow"-->
<!--                                                type="datetime"-->
<!--                                                :editable="false"-->
<!--                                                @focus="focus"-->
<!--                                                placeholder="选择预约时间">-->
<!--                                        </el-date-picker>-->
<!--                                    </el-col>-->
<!--                                </el-row>-->
<!--                                <div v-else>{{tmallData.expect_time}}</div>-->
<!--                            </el-form-item>-->
                            <el-form-item  v-if="tmallData.code" label="预约日期：" label-width="30%">
                                <el-col :span="16">
                                    <el-date-picker
                                            style="width: 140%"
                                            popper-class="selectDate"
                                            v-model="expect_time1"
                                            :picker-options="expireTimeOPtion"
                                            :default-value="dateDefaultShow"
                                            type="date"
                                            :editable="false"
                                            placeholder="选择日期">
                                    </el-date-picker>
                                </el-col>
                            </el-form-item>
                            <el-form-item  v-else label="预约时间：" label-width="30%">
                                {{tmallData.expect_time}}
                            </el-form-item>
                            <el-form-item v-if="tmallData.code" label="预约时间：" label-width="30%">
                                <el-col :span="16">
                                    <el-select v-model="expect_time2" placeholder="请选择"  @change="selectTimeChange">
                                        <el-option
                                                style="text-align:center;"
                                                v-for="item in optionsTime"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                            <el-form-item label="备注：" label-width="30%">
                                <el-col :span="16">
                                    <el-input v-if="tmallData.code" v-model="remark" :span="9" clearable></el-input>
                                    <div v-else>{{tmallData.remark}}</div>                                </el-col>
                            </el-form-item>
                        </el-form>
                        <el-col :span="24" v-if="tmallData.code" align="center" style="margin:30px auto;">
                            <el-button type="primary" @click="submit()">立即预约</el-button>
                        </el-col>
                    </el-col>
                </el-card>
            </el-main>
        </el-container>
        <!-- 选择门店的对话框 -->
        <el-dialog title="预约门店" :visible.sync="addMerchDialogVisible" width="85%" @close="setMerchDialogClosed">
            <el-table :data="tableData" border style="margin: 5px auto;" max-height="400">
<!--                <el-table-column prop="logo" label="门店" align="center">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-image style="width: 80px; height: 80px;" :src="scope.row.logo" fit="cover" ></el-image>-->
<!--                        <div>{{scope.row.merchname}}</div>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column prop="logo" label="安装门店" align="left">
                    <template slot-scope="scope">
                        <div style="font-size: .9rem;font-weight:600;">{{scope.row.merchname}}</div>
                        <div style="color:#888;font-size: .7rem;">{{scope.row.address}}</div>
                        <div style="color: #409EFF;font-size: .7rem;">{{scope.row.contact_phone}}</div>
                        <div v-if="scope.row.distance" style="color: coral;font-size: .8rem;">距离您 {{scope.row.distance}} 公里</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" min-width="35%">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="setMerchChoose(scope.row.merchid,scope.row.merchname,scope.row.contact_phone,scope.row.address)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
<!--            <el-pagination @size-change="sizeChange" @current-change="currentChange"-->
<!--                           :current-page="page" :page-sizes="pageSizes" :pager-count="2"-->
<!--                           layout="total, prev, pager, next, jumper" :total="total">-->
<!--            </el-pagination>-->
        </el-dialog>
    </div>
</template>

<script>
    import { AMapBase, mapGeolocation, mapGetAddress, openNavigationApp, setInfoWindow, setMarker,mapCitySearch } from '@/utils/AMapBase';
    import Vue from 'vue';
    import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data';
    export default {
        name: "reservation",
        data() {
            return{
                // 预约页面id
                id:this.$route.query.id?this.$route.query.id:'',
                // 预约页面code
                code:this.$route.query.code?this.$route.query.code:'',
                //地址信息
                addressData: regionData,
                addressSelections: [],
                //预约安装日期+时间
                expect_time:'',
                //预约日期
                expect_time1:'',
                //预约时间
                expect_time2:'',
                optionsTime:[{
                    value: '9:00-12:00',
                    label: '9:00-12:00'
                }, {
                    value: '12:00-14:00',
                    label: '12:00-14:00'
                }, {
                    value: '14:00-18:00',
                    label: '14:00-18:00'
                }],
                dateDefaultShow: '',
                //备注
                remark:'',
                //获取的天猫数据
                tmallData:'',
                chooseMerch:{
                    //选中的门店名
                    merchname:'',
                    //选中的门店ID
                    merchid:'',
                    //选中的门店联系方式
                    contact_phone:'',
                    //选中的门店地址
                    address:'',
                    //选中省市区
                    province:'',
                    city:'',
                    area:''
                },
                //门店数据
                merchData:[],
                //控制门店dialog显示
                addMerchDialogVisible:false,
                //dialog门店表格数据（显示数组）
                tableData:[],
                //时间限制，禁止选择当前时间之前的日期
                expireTimeOPtion: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() //如果没有后面的-8.64e7就是不可以选择今天的
                    },
                    // 限制时间
                    selectableRange: ['8:00:00 - 18:00:00'],
                },
                //第几页
                page: 1,
                //一页多少条
                size: 10000,
                //总条目数
                total: 0,
                //可选择的一页多少条
                pageSizes: [],
                //图片公共域名
                imgHost:'https://cdn.naoled.cn/',
                //当前选中的规格id
                option:'',
                lat:'',
                lng:'',
                // 接口获取的车型数据
                carsOption:[],
                carsSelectData:{
                    brand_name:'',//选中的品牌
                    car_name:''//选中的车型
                }
            }
        },
        props: {
            // 默认展示地图到时候中心点,不传默认展示自身定位(显示优先权大于location,地图中心最终还是会定位到center) 格式[133.165,26.365]
            center: {
                type: Array,
                default: null
            },
            // 是否获取自身定位(展示自身定位圆点标记)
            location: {
                type: Boolean,
                default: true
            },
            // 是否加载默认自身标记(需要location为true)
            defaultModify: {
                type: Boolean,
                default: true
            },
            // 是否运行修改标记(点击添加标记操作)
            isModify: {
                type: Boolean,
                default: false
            },
            // 最大标记数(超过最大标记数则会先进先出覆盖标记),默认为1,true为无限,需配合isModify使用
            maxMarker: {
                type: [Number, Boolean],
                default: 1
            },
            // 默认展示标记的点 格式[title:'',lnglat:[133.126,26.365]]
            defalutModifyList: {
                type: Array,
                default: () => []
            },
            // 是否需要默认弹框
            isInfoWindow: {
                type: Boolean,
                default: true
            },
            // 标记点击是否默认展示出弹框
            showInfoWindw: {
                type: Boolean,
                default: true
            },
            // 弹框定位,可选值top,right,bottom,left
            infoWindowOffset: {
                type: String,
                default: 'top'
            },
            // 自定义的弹框内容,支持string或者vue异步组件
            infoWindowContent: {
                type: [String, Object],
                default: null
            }
        },
        methods:{
            //获取天猫下单数据
            async getTmallData(id,code) {
                const {data: result} = await this.$http.get('driver/reservation?id='+id+'&code='+code, {}).catch((fail)=>{
                    return this.$message.error(fail.response.data.message);
                });
                if (result.status !== 0) {
                    return this.$message.error(result.message);
                }
                if(result.result.reservation.length==0){
                    this.tmallData=result.result.order;
                    this.initAMap();
                }else{
                    this.tmallData=result.result.reservation;
                    this.initAMap(result.result.reservation.merchid);
                }
            },
            //获取车型数据
            async getCarData() {
                const {data: result} = await this.$http.get('carsearch', {}).catch((fail)=>{
                    return this.$message.error(fail.response.data.message);
                });
                if (result.status !== 0) {
                    return this.$message.error(result.message);
                }
                let carsOption=[];
                let jsonData=result.result;
                for(let i in jsonData){
                    carsOption.push({value:jsonData[i].brand_name,label:jsonData[i].brand_name,children:[]});
                    for(let j in jsonData[i].car){
                        carsOption[i].children.push({value:jsonData[i].car[j].car_name,label:jsonData[i].car[j].car_name});
                    }
                }
                this.carsOption=carsOption;
            },
            // 初始化地图设置(部分设置修改后地图未更新可尝试使用)
            initAMap (id) {
                AMapBase.then(res => {
                    // eslint-disable-next-line no-undef
                    this.map = new AMap.Map('container', { resizeEnable: true, zoom: 20,timeout:10000});

                    // 根据需求获取定位逻辑
                    mapCitySearch().then(res2 => {
                        this.chooseMerch.province=res2.province;
                        this.chooseMerch.city=res2.city;
                        this.getMerchList(id,this.chooseMerch.province,this.chooseMerch.city);
                    });
                    mapGeolocation(this.map).then(res => {
                        // alert(res.addressComponent.province);
                        // alert(res.position.lng,res.position.lat,res.addressComponent.province,res.addressComponent.city,res.addressComponent.district);
                        // 默认自身标点
                        // METHODS:获取定位成功回调事件


                        let province=res.addressComponent.province;
                        let city=res.addressComponent.city;
                        let area=res.addressComponent.district;
                        this.chooseMerch.province=province;
                        this.chooseMerch.city=city;
                        this.chooseMerch.area=area;
                        //因高德定位回调接口返回直辖市city='' 但vue省市区数据直辖市 北京上海天津city分别为是直辖市，重庆city分为直辖市和县，故做出如下处理
                        if(province && area){
                            let data=[province,city,area];
                            let codeArray=[];
                            for(let i in regionData){
                                if(data[0] == regionData[i].label){
                                    codeArray[0]=regionData[i].value;
                                    for(let j in regionData[i].children){
                                        if(data[0]=='北京市' || data[0]=='天津市' || data[0]=='上海市'){
                                            if(regionData[i].children[j].label=='市辖区'){
                                                codeArray[1]=regionData[i].children[j].value;
                                                for(let k in regionData[i].children[j].children){
                                                    if(data[2] == regionData[i].children[j].children[k].label){
                                                        codeArray[2]=regionData[i].children[j].children[k].value;
                                                        this.addressSelections=codeArray;
                                                    }
                                                }
                                            }
                                        }else if(data[0]=='重庆市'){
                                            for(let k in regionData[i].children[j].children){
                                                if(data[2] == regionData[i].children[j].children[k].label){
                                                    codeArray[1]=regionData[i].children[j].value;
                                                    codeArray[2]=regionData[i].children[j].children[k].value;
                                                    this.addressSelections=codeArray;
                                                }
                                            }
                                        }else{
                                            if(data[1] == regionData[i].children[j].label){
                                                codeArray[1]=regionData[i].children[j].value;
                                                for(let k in regionData[i].children[j].children){
                                                    if(data[2] == regionData[i].children[j].children[k].label){
                                                        codeArray[2]=regionData[i].children[j].children[k].value;
                                                        this.addressSelections=codeArray;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            if(res.position.lng && res.position.lat){
                                this.lng=res.position.lng;
                                this.lat=res.position.lat;
                                this.getMerchList(id,province,city,area,this.lng,this.lat);
                            }
                        }
                    }).catch(rej => {
                        // if(rej.message=='Browser not Support html5 geolocation.'){
                        //     return this.$message.error('浏览器不支持原生定位接口');
                        // }
                        // else if(rej.message=='Geolocation permission denied.'){
                        //     return this.$message.error('用户禁用了定位权限');
                        // }else if(rej.message=='Geolocation permission denied.'){
                        //     return this.$message.error('浏览器禁止了非安全域的定位请求');
                        // }
                        // else if(rej.message=='Get geolocation time out.Get ipLocation failed.'){
                        //     return this.$message.error('浏览器定位超时');
                        // }else if(rej.message=='Get geolocation failed.Get ipLocation failed.'){
                        //     return this.$message.error('定位失败');
                        // }else{
                        //     return this.$message.error('定位失败');
                        // }
                    }).finally(() => {
                        // this.center && this.map.setCenter(this.center)
                    })
                })
            },
            //去掉日期的此刻按钮
            focus() {
                this.$nextTick(() => {
                    document.getElementsByClassName('el-button--text')[0].setAttribute('style','display:none')
                });
                document.activeElement.blur();
            },
            test(){

            },
            //禁用时间选择器软键盘
            noInputKeyboard(){
                // 延时1秒，等待日期组件被渲染
                setTimeout(() => {
                    // 获取日期组件
                    let inputs = document.querySelectorAll(".elDatePicker > input");
                    // 设置每个日期组件的onfocus方法，直接blur
                    inputs.forEach((input)=>{
                        input.onfocus =function(){
                            input.blur();
                            // 设置日期组件内部的两个input
                            setTimeout(() => {
                                let elDatePickers = document.querySelectorAll("body > div.el-picker-panel.el-date-picker.el-popper.has-time > div.el-picker-panel__body-wrapper > div > div.el-date-picker__time-header > span > div.el-input.el-input--small > input");
                                elDatePickers.forEach((elDatePicker)=>{
                                    // if(elDatePicker.getAttribute('placeholder')=='选择时间'){
                                    //     elDatePicker.setAttribute("style","background-color:bisque");
                                    // }

                                    elDatePicker.onfocus =function(){
                                        elDatePicker.blur();
                                    }
                                })
                            }, 300);
                        }
                    })
                }, 1000);
            },
            //省市区选择器选择
            handleAddress(value) {
                let deliveryAreaName=this.$refs["cascader"].getCheckedNodes()[0].pathLabels.join(',');
                deliveryAreaName=deliveryAreaName.split(',');
                this.chooseMerch.province=deliveryAreaName[0];
                this.chooseMerch.city=deliveryAreaName[1];
                this.chooseMerch.area=deliveryAreaName[2];
                this.getMerchList('',deliveryAreaName[0],deliveryAreaName[1],deliveryAreaName[2],this.lng,this.lat);
            },
            //根据后端传来的数据进行省市区数据格式的还原
            // getAddressInfo() {
            //     this.addressSelections = [];
            //     this.form.provinceCode = this.form.province ? TextToCode[this.form.province].code : "";
            //     this.form.cityCode = this.form.city ? TextToCode[this.form.province][this.form.city].code : "";
            //     this.form.townCode = this.form.town ? TextToCode[this.form.province][this.form.city][this.form.town].code : "";
            //     this.addressSelections.push(this.form.provinceCode);
            //     this.addressSelections.push(this.form.cityCode);
            //     this.addressSelections.push(this.form.townCode);
            // },
            //获取门店列表
            async getMerchList(id,province,city,area,lng,lat) {
                if(id){
                    const {data: result} = await this.$http.get('ewei_shop_merch_tmall/'+id, {
                    }).catch((fail)=>{
                        return this.$message.error(fail.response.data.message);
                    });
                    if (result.status !== 0) {
                        return this.$message.error('获取门店列表失败');
                    }
                    this.chooseMerch=result.result;
                    this.getTabelData(id);
                }else{
                    if(province){
                        if(area){
                            let url='';
                            if(lat&&lng){
                                url='ewei_shop_merch_tmall?&province='+province+'&city='+city+'&area='+area+'&lng='+lng+'&lat='+lat;
                            }else{
                                url='ewei_shop_merch_tmall?&province='+province+'&city='+city+'&area='+area;
                            }
                            const {data: result} = await this.$http.get(url, {
                            }).catch((fail)=>{
                                return this.$message.error(fail.response.data.message);
                            });
                            if (result.status !== 0) {
                                return this.$message.error('获取门店列表失败');
                            }
                            let arr=result.result;
                            if(arr.length>0){
                                if(arr[0].distance){
                                    for(let i = 0;i<arr.length;i++){
                                        for(let j = 0; j<arr.length-1 -i;j++){
                                            let x = '';
                                            if(arr[j].distance > arr[j+1].distance){
                                                x = arr[j];
                                                arr[j] = arr[j+1]
                                                arr[j+1] = x
                                            }
                                        }
                                    }
                                }
                            }
                            this.merchData=arr;
                            this.getTabelData();
                        }else{
                            let url='';
                            if(lat&&lng){
                                url='ewei_shop_merch_tmall?&province='+province+'&city='+city+'&lng='+lng+'&lat='+lat;
                            }else{
                                url='ewei_shop_merch_tmall?&province='+province+'&city='+city;
                            }
                            const {data: result} = await this.$http.get(url, {
                            }).catch((fail)=>{
                                return this.$message.error(fail.response.data.message);
                            });
                            if (result.status !== 0) {
                                return this.$message.error('获取门店列表失败');
                            }
                            let arr=result.result;
                            if(arr.length>0){
                                if(arr[0].distance){
                                    for(let i = 0;i<arr.length;i++){
                                        for(let j = 0; j<arr.length-1 -i;j++){
                                            let x = '';
                                            if(arr[j].distance > arr[j+1].distance){
                                                x = arr[j];
                                                arr[j] = arr[j+1]
                                                arr[j+1] = x
                                            }
                                        }
                                    }
                                }
                            }
                            this.merchData=arr;
                            this.getTabelData();
                        }
                    }else{
                        return this.$message.error('请先选择安装区域');
                    }
                }
            },
            //关闭选择门店dialog时执行
            setMerchDialogClosed(){
                this.addMerchDialogVisible = false;
                this.page = 1;
            },
            //门店获取表格数据，自行分页（splice）
            getTabelData(id) {
                let that=this;
                let data=JSON.parse(JSON.stringify(that.merchData));
                that.tableData  = data.splice(
                    (that.page - 1) * that.size,
                    that.size
                );
                that.total=that.merchData.length;
            },
            changeVal() {
                this.$forceUpdate();//解决点击计数器失效问题
            },
            //选中的门店数据
            setMerchChoose(id,merchname,contact_phone,address){
               this.chooseMerch.merchid=id;
               this.chooseMerch.merchname=merchname;
               this.chooseMerch.contact_phone=contact_phone;
               this.chooseMerch.address=address;
               this.addMerchDialogVisible = false;
            },
            //选中时间事件
            selectTimeChange(){
               this.expect_time=this.expect_time1+this.expect_time2;
               console.log(this.expect_time)
            },
            //提交订单数据
            submit(){
                if(!this.chooseMerch.merchid){
                    this.$message.error('请选择安装门店');
                }else if(!this.expect_time){
                    this.$message.error('请选择预约安装时间');
                }else{
                    //验证成功，发起提交预约的网络请求
                    this.postData();
                }
            },
            async postData(){
                const { data: res } = await this.$http.post('driver/post?id='+this.id+'&code='+this.code, {merchid:this.chooseMerch.merchid,expect_time:this.expect_time,remark:this.remark,brand_name:this.carsSelectData.brand_name,car_name:this.carsSelectData.car_name}).catch((fail)=>{
                    return this.$message.error(fail.response.data.message);
                });
                if (res.status == 0) {
                    this.$message.success('提交预约成功');
                    setTimeout(() => {
                        this.$router.go(0);
                    },500)
                }else{
                    this.$message.error(res.message);
                }
            },
            showMerchList(){
                this.getMerchList('',this.chooseMerch.province,this.chooseMerch.city,this.chooseMerch.area,this.lng,this.lat);
                this.addMerchDialogVisible = true;
            },
            carsSelect(e){
                this.carsSelectData.brand_name=e[0];
                this.carsSelectData.car_name=e[1];
            }
        },
        mounted() {
            let date = new Date;
            date = date.getFullYear() + '/'
                + (date.getMonth()+1) + '/'   // 这里加1是因为getMonth()的返回值是为0~11
                + date.getDate() + ' '
                + date.getHours() + ':'
                + date.getMinutes() + ':'
                + date.getSeconds();
            date = Date.parse(new Date(date))/1000; // 转换成时间戳，返回值是ms，除1000后转化成秒
            date += 86400; // 一天是86400秒
            let newDate = new Date(parseInt(date) * 1000); // 把时间戳转换成日期
            this.dateDefaultShow = newDate.getFullYear() + '/'
                + (newDate.getMonth()+1) + '/'
                + newDate.getDate() + ' '
                + newDate.getHours() + ':'
                + newDate.getMinutes() + ':'
                + newDate.getSeconds();
            this.expect_time1 = newDate.getFullYear() + '/'
                + (newDate.getMonth()+1) + '/'
                + newDate.getDate() + ' ';
        },
        created() {
            this.getTmallData(this.id,this.code);
            this.getCarData();
            this.noInputKeyboard();
        },
    }
</script>

<style scoped>
    .center{
        text-align: center;
    }
    .title{
        color: #fff;
        line-height: 3.5rem;
    }
    .myNote{
        white-space: pre-wrap;
        word-wrap: break-word;
    }
    ::v-deep .el-dialog__body{
        padding: 5px!important;
    }
    ::v-deep .time-select-item{
        text-align: center;
    }

</style>
<style>
    .selectDate .el-time-spinner__wrapper{
        width: 100% !important;
    }
    .selectTime .el-scrollbar__view{
        text-align: center;
    }
    /*当屏幕宽度 最小为768px 最大为991px时*/
    @media screen and (max-width:767px) {
        .selectDate{
            left: 35px!important;
        }
        .selectDate .el-time-panel{
            width:146px;
        }
        .myascader .el-cascader-menu{
            min-width: auto!important;
        }
        .myascader .el-scrollbar__view{
            width: 120px!important;
        }
        .mycarsData{
            margin-left: -30px!important;
        }
        .mycarsData .el-cascader-menu{
            min-width: auto!important;
        }
        .mycarsData .el-scrollbar__view{
            width: 160px!important;
        }
    }
</style>