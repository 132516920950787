<template>
    <div>
        <el-container>
<!--            <el-header class="el-header-fixed center" style="background-color: #409EFF;height:5vh;">-->
<!--                <span class="title" @click="goBack"><i class="el-icon-arrow-left"></i>返回</span>-->
<!--            </el-header>-->
            <el-main>
                <div class="app-container" style="height: 100vh;">
                    <div id="container"></div>
                </div>
                <div class="map-content">
                    <van-popup class="map-popup" v-model="popupShow" position="bottom" style="padding-bottom:10px; " overlay-class="map-content">
                        <div class="popup-item"
                             v-for="(item) in popupData"
                             :key="item.type"
                             @click="popupItemClick(item)">
                            {{ item.name }}
                        </div>
                    </van-popup>
                </div>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import { AMapBase, mapGeolocation, mapGetAddress, openNavigationApp, setInfoWindow, setMarker } from '@/utils/AMapBase';
    import Vue from 'vue';

    window._AMapSecurityConfig = {
        securityJsCode: 'da70426349d1e1f0abc0e1017e3195e6'
    };
    export default {
        name: "index",
        data() {
            return {
                id:this.$route.query.id?this.$route.query.id:'6660',
                lat:'',
                lng:'',
                map: null,
                address: '',
                title:'无',
                mobile:'',
                infoWindow: null, // 保存信息弹出对象信息
                markerList: [], // 点击后标记保存的信息 {regeocode: 当前点地图信息, marker: 标记构建实例}
                markerData: null, // 当前弹框显示的标记数据
                popupShow: false, // 导航列表展示
                popupData: [{ name: '腾讯地图', type: 'TX' }, { name: '百度地图', type: 'BD' }, { name: '高德地图', type: 'GD' }, { name: '苹果地图', type: 'PG' }]
            }
        },
        props: {
            // 默认展示地图到时候中心点,不传默认展示自身定位(显示优先权大于location,地图中心最终还是会定位到center) 格式[133.165,26.365]
            center: {
                type: Array,
                default: null
            },
            // 是否获取自身定位(展示自身定位圆点标记)
            location: {
                type: Boolean,
                default: true
            },
            // 是否加载默认自身标记(需要location为true)
            defaultModify: {
                type: Boolean,
                default: true
            },
            // 是否运行修改标记(点击添加标记操作)
            isModify: {
                type: Boolean,
                default: false
            },
            // 最大标记数(超过最大标记数则会先进先出覆盖标记),默认为1,true为无限,需配合isModify使用
            maxMarker: {
                type: [Number, Boolean],
                default: 1
            },
            // 默认展示标记的点 格式[title:'',lnglat:[133.126,26.365]]
            defalutModifyList: {
                type: Array,
                default: () => []
            },
            // 是否需要默认弹框
            isInfoWindow: {
                type: Boolean,
                default: true
            },
            // 标记点击是否默认展示出弹框
            showInfoWindw: {
                type: Boolean,
                default: true
            },
            // 弹框定位,可选值top,right,bottom,left
            infoWindowOffset: {
                type: String,
                default: 'top'
            },
            // 自定义的弹框内容,支持string或者vue异步组件
            infoWindowContent: {
                type: [String, Object],
                default: null
            }
        },
        mounted() {
            this.getMerch();
        },


        methods: {
            async getMerch(){
                let that=this;
                if(that.id){
                    const {data: result} = await this.$http.get('ewei_shop_merch_tmall/'+that.id, {
                    }).catch((fail)=>{
                        return this.$message.error(fail.response.data.message);
                    });

                    if (result.status == 0) {
                        that.lat=result.result.lat;
                        that.lng=result.result.lng;
                        that.title=result.result.merchname;
                        that.mobile=result.result.contact_phone;
                        //调用地图初始化方法
                        this.amap("53dfa5272dfd8586ece62d354a8c58ec").then((amap) => {
                            AMap.plugin(['AMap.ToolBar', 'AMap.Driving','AMap.Geolocation','AMap.CitySearch'], () => {});
                            this.initAMap();
                        });
                    }else{
                        this.$message.error(result.message);
                    }
                }else{
                    alert('导航失败：导航链接门店信息不完整！');
                }
            },
            initAMap() {
                let that = this;
                //基本地图加载
                let map = new AMap.Map("container", {
                    resizeEnable: true,
                    center: [that.lng, that.lat],//地图中心点
                    zoom: 15, //地图显示的缩放级别
                });
                // 构造点标记
                var marker = new AMap.Marker({
                    position: [that.lng, that.lat] // 点标记在地图上显示的位置，数组内对应经纬度
                });
                // 将点标记添加到地图map上
                map.add(marker);
                that.setMyMarker(map);
            },
            amap(key){
                return new Promise(resolve=>{

                    let url=`https://webapi.amap.com/maps?v=2.0&key=${key}&plugin=AMap.CitySearch&callback=AMapLoad`

                    let script=document.createElement("script");

                    script.src=url;

                    document.body.appendChild(script);

                    window.AMapLoad=function(){

                        resolve(window.AMap)

                    }

                })

            },
            // 点击导航按钮方法
            navigation (data) {
                console.log(data)
                this.popupShow = true
                this.markerData = data
                // METHODS:点击导航自定义方法
                this.$emit('navigation', data)
            },
            // 调起app导航方法
            popupItemClick (item) {
                openNavigationApp(item.type, this.markerData)
                this.popupShow = false
            },
            goBack() {
                this.$router.go(-1);
            },
            setMyMarker(map){
                let _that=this;
                const opts = {
                    lnglat: [_that.lng, _that.lat],
                    title: _that.title,
                    key: _that.markerList.length ? _that.markerList[_that.markerList.length - 1].key + 1 : 1,
                    mobile:_that.mobile
                };
                const template = `<div  style="display: grid;grid-template-areas: '. .';font-size: 14px;width: 100%;"><div style="padding: 0 20px;display: grid;align-content: center;border-right:1px solid #EAEAEA;color:#355DB4;text-align: center; " @click='navigation'>导航</div><div style="padding: 0 20px;text-align: center;"><div>`+opts.mobile+`</div><div>`+opts.title+`</div></div></div>`
                const InfoContent = Vue.extend({
                    template,
                    methods: {
                        navigation () {
                            return _that.navigation(opts)
                        }
                    }
                });

                _that.infoWindow = setInfoWindow('top', new InfoContent().$mount().$el);
                _that.infoWindow.open(map, opts.lnglat);
            }
        },
    }
</script>

<style scoped>
    #app-container {
        width: 100%;
        position: relative;
    }

    #container {
        width: 100%;
        height: 100%;
    }

    .center{
    }
    .title{
        color: #fff;
        line-height: 5vh;
    }
    .el-header{
        padding: 0 10px!important;
    }
    .el-main{
        padding: 0!important;
    }
    .map-components {
        width: 100%;
        height: 100%;
    }
    .map-components .container-box {
        width: 100%;
        height: 100%;
    }
    .map-content .map-popup .popup-item{
        text-align: center;
        padding: 15px 0;
        background: #ffffff;
        border-bottom: 1px solid #e0e0e0;
    }
    .map-content .map-popup .popup-item:last-child{
        border-bottom: none;
    }
</style>
