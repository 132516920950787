<template>
    <div>
        <!--        面包屑导航区域-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>权限管理</el-breadcrumb-item>
            <el-breadcrumb-item>权限列表</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 卡片视图 -->
        <el-card>
            <!--   :data绑定数据源    border添加表格横竖边框线   stripe实现表格隔行变色
            el-table-column是索引列   label是列的标题    prop是对应的具体属性值(根据响应数据) -->
            <el-table :data="rightslist" border stripe>
                <el-table-column type="index"></el-table-column>
                <el-table-column label="权限名称" prop="authName"></el-table-column>
                <el-table-column label="路径" prop="path"></el-table-column>
                <el-table-column label="权限等级" prop="level">
                    <!-- 作用域插槽的形式来自定义输出格式  scope接收所有数据 -->
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.level === '0'">一级</el-tag>
                        <el-tag type="success" v-else-if="scope.row.level === '1'">二级</el-tag>
                        <el-tag type="warning" v-else>三级</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="showEditDialog(scope.row.id)" icon="el-icon-edit">编辑</el-button>
                        <el-button size="mini" type="danger" @click="removeRightsById(scope.row.id)" icon="el-icon-delete">删除</el-button>
                        <el-button size="mini" type="warning" icon="el-icon-setting"
                                   @click="showSetRightDialog(scope.row)">分配权限
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <!-- 分配权限的对话框 -->
        <el-dialog title="分配权限" :visible.sync="setRightDialogVisible" @close="setRightDialogClosed" @click="showSetRightDialog(scope.row)">
            <!-- 树形控件 -->
            <el-tree ref="treeRef" :data="rightslist" :props="treeProps" show-checkbox node-key="id" default-expand-all :default-checked-keys="defKeys"></el-tree>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="setRightDialogVisible = false">取 消</el-button>
                <el-button type="primary"  @click="allotRights">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 2. 编辑角色对话框 -->
        <el-dialog title="修改权限" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
            <!-- 内容主体区域 -->
            <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="80px">
                <el-form-item label="权限名称" prop="roleName">
                    <el-input v-model="editForm.rightsName" ></el-input>
                </el-form-item>
                <el-form-item label="权限路径" prop="roleDesc">
                    <el-input v-model="editForm.rightsPath"></el-input>
                </el-form-item>
                <el-form-item label="权限等级" prop="roleDesc">
                    <el-input v-model="editForm.rightsLevel"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible=false">取 消</el-button>
                <el-button type="primary" @click="editRightsInfo">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Rights",
        data() {
            return {
                //3.控制分配权限对话框的显示与隐藏
                setRightDialogVisible: false,
                // 2. 控制编辑权限对话框的显示与隐藏
                editDialogVisible: false,
                //查询到的角色对象
                editForm: {},
                editFormRules: {
                    rightsName: [{ required: true, message: '请输入权限名称', trigger: 'blur' }],
                },
                // 树形控件的属性绑定对象
                treeProps: {
                    children: 'children',
                    label: 'authName'
                },
                //当前即将分配权限的角色id
                roleId: [],
                //默认选中的节点id值数组
                defKeys: [],
                //所有的权限列表数据
                rightslist: [
                    {
                        authName: '订单管理',
                        id: 2,
                        level: '0',
                        path: 'orders',
                        pid: 0,
                        children: [
                            {id: 11, authName: '新建订单', path: 'createOrder', children: []},
                            {id: 12, authName: '订单列表', path: 'orders', children: []},
                            {id: 13, authName: '售后管理', path: 'afterSales', children: []}
                        ]
                    },
                    {
                        authName: '商品管理',
                        id: 3,
                        level: '0',
                        path: 'goods',
                        pid: 0,
                        children: []
                    },
                    {
                        authName: '门店管理',
                        id: 4,
                        level: '0',
                        path: 'merch',
                        pid: 0,
                        children: []
                    },
                    {
                        authName: '财务管理',
                        id: 5,
                        level: '0',
                        path: 'finance',
                        pid: 0,
                        children: []
                    },
                    {
                        authName: '人员管理',
                        id: 6,
                        level: '0',
                        path: 'users',
                        pid: 0,
                        children: [
                            {id: 61, authName: '用户列表', path: 'users', children: []}
                        ]
                    },
                    {
                        authName: '权限管理',
                        id: 7,
                        level: '0',
                        path: 'rights',
                        pid: 0,
                        children: [
                            {id: 71, authName: '权限列表', path: 'rights', children: []},
                            {id: 72, authName: '角色列表', path: 'roles', children: []}
                        ]
                    },
                    {
                        authName: '系统管理',
                        id: 8,
                        level: '0',
                        path: 'system',
                        pid: 0,
                        children: []
                    }
                ]
            }
        },
        created() {
            //生命周期函数created发起数据请求
            //获取所有的权限，调用getRightsList这个函数
            // this.getRightsList();
        },
        methods: {
            //1.4.1所有权限列表API接口中请求方式是get，type选择list(列表性质结构)
            //获取权限列表方法
            async getRightsList() {
                const {data: res} = await this.$http.get('rights/list');
                if (res.meta.status !== 200) {
                    return this.$message.error('获取权限列表失败！');
                }
                //请求成功，将请求到的数据挂载到rightsList中，供页面的模板结构使用
                this.rightsList = res.data;
                console.log(this.rightsList);
            },
            //展示分配权限的对话框
            async showSetRightDialog(role) {
                this.roleId = role.id
                //获取所有权限列表
                // const {data: res} = await this.$http.get('rights/tree');
                // if (res.meta.status !== 200) {
                //     return this.$message.error('获取权限数据失败！');
                // }
                // //获取到的权限数据，保存到data中
                // this.rightslist = res.data;
                //递归获取三级节点的id
                this.getLeafKeys(role, this.defKeys);

                this.setRightDialogVisible = true;
            },
            // 展示编辑权限的对话框
            async showEditDialog(id) {
                this.editDialogVisible = true;
                const { data: res } = await this.$http.get('rights/' + id);
                if (res.meta.status !== 200) {
                    return this.$message('查询角色信息失败！');
                }
                //查询到的数据保存到editForm 上
                this.editForm = res.data;
            },
            editRightsInfo() {
                this.$refs.editFormRef.validate(async (valid) => {
                    if (!valid) return;
                    const { data: res } = await this.$http.put('rights/' + this.editForm.rightsId, {
                        roleName: this.editForm.rightsName,
                        roleDesc: this.editForm.rightsDesc,
                    });
                    if (res.meta.status !== 200) {
                        return this.$message.error('更新角色信息失败！');
                    }
                    this.editDialogVisible = false;
                    this.getRightsList();
                    this.$message.success('更新角色信息成功！');
                })
            },
            //通过递归函数的形式，获取角色列表下所有三级权限的id，并保存到defKeys数组中
            getLeafKeys(node, array) {
                //如果当前node节点不包含children属性，则是三级节点
                if (!node.children) {
                    return array.push(node.id);
                }
                //递归完毕后，把所有三级节点都遍历完，并且把id保存到array中
                node.children.forEach(item =>
                    this.getLeafKeys(item, array));
            },
            // 监听分配权限对话框的关闭事件
            setRightDialogClosed() {
                this.defKeys = [];
            },
            //组件你可以认为是一个盒子，ref就是你给这个盒子打了一个特殊的标签，然你有了这个标签，你就可以去这个盒子里面拿她的东西
            //点击确定为角色分配权限
            async allotRights(){
                const keys = [
                    //...就是把两个数组合并成一个数组   ...是展开 相当于把括号去掉
                    ...this.$refs.treeRef.getCheckedKeys(), //获取所有已选中节点的id数组
                    ...this.$refs.treeRef.getHalfCheckedNodes()  //获取所有半选中节点的id数组
                ]
                const  idStr = keys.join(',') //数组变成英文逗号分隔的字符串
                const {data:res} = await this.$http.post(`roles/${this.roleId}/rights`,{rids:idStr})
                if(res.meta.status !== 200) {
                    return this.$message.error('分配权限失败！')
                }
                this.$message.success('分配权限成功！')
                this.getRightsList()
                this.setRightDialogVisible=false
            },
            async removeRightsById(id) {
                console.log(1)
                const confirmResult = await this.$confirm('此操作将永久删除该权限, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).catch((error) => error);
                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消删除');
                }
                const {data:res} = await this.$http.delete('rights/' + id);
                if(res.meta.status !==200){
                    return this.$message.error('删除权限失败！');
                }
                this.$message.success('删除权限成功！');
                this.getRightsList();
            },
            editDialogClosed() {
                this.$refs.editFormRef.resetFields();
            }
        }
    }
</script>

<style scoped>

</style>