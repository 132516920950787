<template>
    <div class="login_container">
        <div class="login_box">
            <div class="avatar_box">
                <img src="../assets/logo.jpg" alt=""/>
            </div>
            <!-- 登录表单区域 -->
            <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login_form">
                <!--  用户名 -->
                <el-form-item prop="username">
                    <el-input v-model="loginForm.username" prefix-icon="el-icon-s-custom"></el-input>
                </el-form-item>
                <!--  密码 -->
                <el-form-item prop="password">
                    <el-input v-model="loginForm.password" type="password" prefix-icon="el-icon-lock"></el-input>
                </el-form-item>
                <!--  按钮区域 -->
                <el-form-item class="btns">
                    <el-button type="primary" @click="login">登录</el-button>
                    <el-button type="info" @click="resetLoginForm">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                // 登录表单的数据绑定对象
                loginForm: {
                    username: 'admin',
                    password: '123456'
                },
                // 表单验证规则对象
                loginFormRules: {
                    //验证用户名是否合法
                    username: [
                        {required: true, message: '请输入登录名称', trigger: 'blur'},
                        {min: 3, max: 8, message: '长度在 3 到 8 个字符', trigger: 'blur'},
                    ],
                    // 验证密码是否合法
                    password: [
                        {required: true, message: '请输入登录密码', trigger: 'blur'},
                        {min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {
            // 点击重置按钮，重置登录表单
            resetLoginForm() {
                // console.log(this) // VueComponent
                this.$refs.loginFormRef.resetFields()
            },
            login() {
                // valid是一个布尔值，如果表单格式验证合法就为true
                //先判断valid是否为true，如果不等于true就直接返回，等于true发起请求

                // this.$refs.loginFormRef.validate(async (valid) => {
                //     if (!valid) return;
                //     // 发起请求，方式是post，并且携带参数
                //     // data属性结构赋值，重命名为res
                //     const { data: res } = await this.$http.post('login', this.loginForm);
                //     if (res.meta.status != 200) return this.$message.error('登陆失败');

                this.$message.success('登陆成功');

                // 将登录成功之后的token保存到客户端的sessionStorage中（会话期间的存储机制）（所以不放在localStorage）
                // 项目中除了登录之外的其他API接口，必须在登录之后才能访问

                // window.sessionStorage.setItem('token',res.data.token);

                // 通过编程式导航跳转到后台主页，路由地址是/home
                this.$router.push('/home');
                // })
            }
        }
    }
</script>

<style lang="less" scoped>
    .login_container {
        background-color: #2b4b6b;
        height: 100%;
    }

    .login_box {
        width: 450px;
        height: 300px;
        background-color: #fff;
        border-radius: 5px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .avatar_box {
        height: 130px;
        width: 130px;
        border: 1px solid #eee;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0 0 10px #ddd;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        /*less的嵌套语法*/

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #eee;
        }
    }

    .login_form {
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 0 20px;
        //默认form的box-sizing:content-box
        box-sizing: border-box;
    }

    .btns {
        display: flex;
        justify-content: flex-end;
    }
</style>