<template>
    <div>
        <el-container>
            <!--            <el-header class="el-header-fixed center" style="background-color: #409EFF;height:5vh;">-->
            <!--                <span class="title" @click="goBack"><i class="el-icon-arrow-left"></i>返回</span>-->
            <!--            </el-header>-->
            <el-main style="height:100vh;">
                <div class="app-container" style="height: 100vh;">
                    <!--        搜索框-->
                    <div class="map-box">
                        <el-input
                                v-model="mapAddress"
                                placeholder="请输入所在位置"
                                id="tipinput"
                                style="width: 50%;margin:0 10px;"
                                @keyup.enter.native="searchKeyWord">
                        </el-input>
                        <el-button type="primary" @click="searchKeyWord"  icon="el-icon-search" ></el-button>
                    </div>
                    <!--        搜索结果选择窗-->
                    <div class="map_search_result">
                        <ul class="map_ul">
                            <li  @click="markerResult(item)" v-for="(item,index) in poiList" :key="index">{{item.name}}</li>
                        </ul>
                    </div>
                    <div id="container"></div>
                </div>
                <!--                <div class="map-content">-->
                <!--                    <van-popup class="map-popup" v-model="popupShow" position="bottom" style="padding-bottom:10px; " overlay-class="map-content">-->
                <!--                        <div class="popup-item"-->
                <!--                             v-for="(item) in popupData"-->
                <!--                             :key="item.type"-->
                <!--                             @click="popupItemClick(item)">-->
                <!--                            {{ item.name }}-->
                <!--                        </div>-->
                <!--                    </van-popup>-->
                <!--                </div>-->
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import AMapLoader from '@amap/amap-jsapi-loader';
    window._AMapSecurityConfig = {
        securityJsCode: 'da70426349d1e1f0abc0e1017e3195e6',//你的秘钥
    }
    export default {
        name: "index",
        data() {
            return {
                id:this.$route.query.id?this.$route.query.id:'6660',
                lat:'',
                lng:'',
                map: null,
                address: '',
                title:'无',
                mobile:'',
                infoWindow: null, // 保存信息弹出对象信息
                markerList: [], // 点击后标记保存的信息 {regeocode: 当前点地图信息, marker: 标记构建实例}
                markerData: null, // 当前弹框显示的标记数据
                popupShow: false, // 导航列表展示
                popupData: [{ name: '腾讯地图', type: 'TX' }, { name: '百度地图', type: 'BD' }, { name: '高德地图', type: 'GD' }, { name: '苹果地图', type: 'PG' }],
                mapAddress:'',
                showSearchResult:false,
                poiList:[],
                province:'',
                city:''
            }
        },
        mounted() {
            this.initMap();
        },


        methods: {
            initMap(){
                AMapLoader.load({
                    key: '53dfa5272dfd8586ece62d354a8c58ec', // 申请好的Web端开发者Key，首次调用 load 时必填
                    version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    plugins: [
                        "AMap.CitySearch",
                        "AMap.Geocoder",
                        "AMap.Autocomplete", //输入提示插件
                        "AMap.PlaceSearch", //POI搜索插件
                        "AMap.Scale", //右下角缩略图插件 比例尺
                        "AMap.OverView", //地图鹰眼插件
                        "AMap.ToolBar", //地图工具条
                        "AMap.MapType", //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
                        "AMap.PolyEditor", //编辑 折线多，边形
                        "AMap.CircleEditor", //圆形编辑器插件
                        "AMap.Geolocation" //定位控件，用来获取和展示用户主机所在的经纬度位置
                    ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                    AMapUI: { // 是否加载 AMapUI，缺省不加载
                        version: '1.1', // AMapUI 缺省 1.1
                        plugins: [] // 需要加载的 AMapUI ui插件
                    },
                    resizeEnable: true,
                }).then((AMap)=>{
                    const that = this;
                    that.map = new AMap.Map("container",{  //设置地图容器id
                        viewMode:"3D",    //是否为3D地图模式
                        zoom:13,          //初始化地图级别
                    });

                    that.map.addControl(new AMap.ToolBar()); // 添加组合控件
                    that.map.addControl(new AMap.Scale()); // 添加比例尺
                    that.geocoder = new AMap.Geocoder()
                    that.mapSearchInit();
                    AMap.plugin('AMap.CitySearch', function () {
                        var citySearch = new AMap.CitySearch()
                        citySearch.getLocalCity(function (status, result) {
                            if (status === 'complete' && result.info === 'OK') {
                                that.province=result.province;
                                that.city=result.city;
                                // 查询成功，result即为当前所在城市信息
                            }
                        })
                    })
                }).catch(e=>{
                    console.log(e);
                })
            },
            //新增标记
            showLocation(data){
                let marker = new AMap.Marker({
                    position: new AMap.LngLat( data[0],data[1]), //参数为经纬度
                    icon:'https://cdn.naoled.cn/images/1/2023/09/s4TeqJiJhePFh9pzDqRijPiCPutHj9.jpg'
                })
                this.map.clearMap()// 清除所有覆盖物（点标志）
                this.map.add(marker)// 添加点标志
                this.showInfoWindow(marker);//自定义信息窗体
            },
            //自定义信息窗体
            showInfoWindow(marker){
                let infoWindow = new AMap.InfoWindow({
                    isCustom: true, //是否自定义信息窗体
                    content:  `<div style=" border-radius: 5px;border: 1px solid #cccccc;background: crimson;color: #fff;padding: 5px;"> ${this.address}</div>`,
                    closeWhenClickMap: false,
                    zIndex: 999,
                    offset: new AMap.Pixel(16, -35)
                });
                infoWindow.open(this.map, marker.getPosition());
            },
            //获取当前定位
            getCurrentLocation(){
                const that = this;
                that.geolocation = new AMap.Geolocation({
                    timeout: 3000,          //超过3秒后停止定位，默认：5s
                    enableHighAccuracy: true,
                    zoomToAccuracy: true,   //定位成功后是否自动调整地图视野到定位点
                });
                that.geolocation.getCurrentPosition(function(status,result){
                    //备注：getCurrentPosition方法会调用超时或失败：
                    //Get geolocation time out：浏览器定位超时，包括原生的超时，可以适当增加超时属性的设定值以减少这一现象。
                    //另外还有个别浏览器（如google Chrome浏览器等）本身的定位接口是黑洞，通过其请求定位完全没有回应，也会超时返回失败。
                    //Get geolocation failed：定位失败，Chrome、火狐以及部分套壳浏览器接入的定位服务在国外，有较大限制，失败率高。
                    console.log(status,result);
                    if(status=='complete'){
                        that.onComplete(result)
                    }else{
                        that.onError(result) //失败后可使用getCityInfo获取非精准定位（具体到省市）
                    }
                });
            },
            //解析定位结果
            onComplete(data) {
                console.log('定位结果：' + data.position) //经纬度信息
                let lnglat = data.position;
                let marker = new AMap.Marker({  //创建标记
                    position: new AMap.LngLat(lnglat[0], lnglat[1])
                })
                this.map.clearMap()// 清除所有覆盖物（点标志）
                this.map.add(marker)// 添加点标志
                let that = this
                //经纬度转换为中文地址详情
                that.geocoder.getAddress(lnglat, function (status, result) {
                    if (status === 'complete' && result.regeocode) {
                        that.address = result.regeocode.formattedAddress;
                        that.showInfoWindow(marker);//自定义信息窗体
                    } else {
                        that.$message.error('根据经纬度查询地址失败')
                    }
                })
            },
            //解析定位错误信息
            onError(data) {
                this.getLngLatLocation()
            },
            //在获取具体定位失败时调用的代码：（非精准定位！！！）
            getLngLatLocation() {
                const that = this;
                that.geolocation.getCityInfo(function (status, result) {
                    if (status === 'complete') {
                        let data = result.position;
                        that.address = result.province + result.city;
                        if(data){
                            that.showLocation(data)
                        }
                    } else {
                        that.$message.error('获取地址失败')
                    }
                })
            },
            // 初始化搜索
            mapSearchInit(){
                let autoOptions = {
                    input: "tipInput",
                }
                let autoCompleteComponent= new AMap.Autocomplete(autoOptions);
                this.autoCompleteComponent = autoCompleteComponent;
                // 注册placeSearch组件
                this.placeSearchComponent = new AMap.PlaceSearch()
            },
            //根据输入内容查询
            searchKeyWord(){
                let that= this;
                that.placeSearchComponent.search(that.mapAddress, function (status, result) {
                    console.log(result)
                    if(status==='complete' && result.info === "OK"){
                        that.poiList = result.poiList.pois
                    }else{
                        that.poiList = []
                        that.$message({
                            message: "没有查到结果",
                            type: "warning",
                        });
                    }
                })
            },
            //选择搜索的内容
            markerResult(data){
                this.address = data.name;
                var marker = new AMap.Marker({
                    position: [Number(data.location.lng),Number(data.location.lat)],
                });
                this.map.clearMap()// 清除所有覆盖物（点标志）
                this.map.add(marker)// 添加点标志
                this.showInfoWindow(marker);
                setTimeout(() => {
                    this.map.setCenter(data.location);
                    this.map.setZoom(15);
                }, 50)
                let thisPosition = {
                    address: this.address,
                    lng: data.location.lng,
                    lat: data.location.lat
                };
                this.$emit("select",thisPosition);
                let geocoder = new AMap.Geocoder({
                    // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                    city: "全国",
                });
                //3.根据地理信息（地址）进行搜索获取详细信息！
                geocoder.getAddress([data.location.lng,data.location.lat], (status, result) => {
                    if (status === "complete" && result.regeocode) {
                        console.log("res", result);
                        if(result.regeocode.addressComponent.province=='上海市' || result.regeocode.addressComponent.province=='重庆市' || result.regeocode.addressComponent.province=='北京市' || result.regeocode.addressComponent.province=='天津市'){
                            this.getMerch(result.regeocode.addressComponent.province,result.regeocode.addressComponent.province,data.location.lat,data.location.lng);
                        }else{
                            if(!result.regeocode.addressComponent.city){
                                this.getMerch(result.regeocode.addressComponent.province,result.regeocode.addressComponent.district,data.location.lat,data.location.lng);
                            }else{
                                this.getMerch(result.regeocode.addressComponent.province,result.regeocode.addressComponent.city,data.location.lat,data.location.lng);
                            }
                        }
                        //需求：固定的窗体信息进行展示！
                    } else {
                        log.error("根据经纬度查询地址失败");
                    }
                });
                this.poiList = [];
            },
            // 点击导航按钮方法
            navigation (data) {
                console.log(data)
                this.popupShow = true
                this.markerData = data
                // METHODS:点击导航自定义方法
                this.$emit('navigation', data)
            },
            // 调起app导航方法
            popupItemClick (item) {
                openNavigationApp(item.type, this.markerData)
                this.popupShow = false
            },
            goBack() {
                this.$router.go(-1);
            },
            setMyMarker(map){
                let _that=this;
                const opts = {
                    lnglat: [_that.lng, _that.lat],
                    title: _that.title,
                    key: _that.markerList.length ? _that.markerList[_that.markerList.length - 1].key + 1 : 1,
                    mobile:_that.mobile
                };
                const template = `<div  style="display: grid;grid-template-areas: '. .';font-size: 14px;width: 100%;"><div style="padding: 0 20px;display: grid;align-content: center;border-right:1px solid #EAEAEA;color:#355DB4;text-align: center; " @click='navigation'>导航</div><div style="padding: 0 20px;text-align: center;"><div>`+opts.mobile+`</div><div>`+opts.title+`</div></div></div>`
                const InfoContent = Vue.extend({
                    template,
                    methods: {
                        navigation () {
                            return _that.navigation(opts)
                        }
                    }
                });

                _that.infoWindow = setInfoWindow('top', new InfoContent().$mount().$el);
                _that.infoWindow.open(map, opts.lnglat);
            },
            async getMerch(province,city,lat,lng){
                const {data: result} = await this.$http.get('ewei_shop_merch?&province='+province+'&city='+city+'&lng='+lng+'&lat='+lat, {
                }).catch((fail)=>{
                    return this.$message.error(fail.response.data.message);
                });
                if (result.status !== 0) {
                    return this.$message.error('获取门店列表失败');
                }
                let arr=result.result;
                if(arr.length>0){
                    if(arr[0].distance){
                        for(let i = 0;i<arr.length;i++){
                            for(let j = 0; j<arr.length-1 -i;j++){
                                let x = '';
                                if(arr[j].distance > arr[j+1].distance){
                                    x = arr[j];
                                    arr[j] = arr[j+1]
                                    arr[j+1] = x
                                }
                            }
                        }
                    }
                }else{
                    return this.$message.info('附近暂无门店');
                }
                this.loadMarkers(arr,"emp")
            },
            //加载marker点

            loadMarkers(Markerdata,Mtype){

                var gmarker;

                var _this=this;

                // map.clearMap() //加载marker点时先清地图

                Markerdata.forEach(function(marker,i) {
                    if(marker.lng && marker.lat){
                        gmarker = new AMap.Marker({

                            map: _this.map,

                            icon: 'https://cdn.naoled.cn/images/1/2023/09/Z7w70D0F03v5dV3l9P3pqW3w459p0F.png',

                            position: [marker.lng,marker.lat],

                            offset: new AMap.Pixel(-13, -30)//往左往上

                        });

                        _this.map.add(gmarker);

                        //定位图片下方标注

                        gmarker.setLabel({

                            offset: new AMap.Pixel(0, 0),  //设置文本标注偏移量

                            content: "<div style='text-align: center;'>" + marker.merchname + "</div><div style='text-align: center;'>距您"+marker.distance+"公里</div>", //设置文本标注内容

                            direction: 'top' //设置文本标注方位

                        });
                    }
                });
            }
        },
    }
</script>

<style scoped>
    #app-container {
        width: 100%;
        position: relative;
    }

    #container {
        width: 99%;
        margin: auto;
        height: 92vh;
    }
    .map_ul{
        margin: 0 10px 10px 10px;
        padding: 5px 5px 5px 10px;
        font-size: 14px;
        color: blue;
    }
    .map_ul li{
        font-size: 15px;
        width: 400px;
        padding: 5px 0;
    }
    .center{
    }
    .title{
        color: #fff;
        line-height: 5vh;
    }
    .el-header{
        padding: 0 10px!important;
    }
    .el-main{
        padding: 0!important;
    }
    .map-components {
        width: 100%;
        height: 100%;
    }
    .map-components .container-box {
        width: 100%;
        height: 100%;
    }
    .map-content .map-popup .popup-item{
        text-align: center;
        padding: 15px 0;
        background: #ffffff;
        border-bottom: 1px solid #e0e0e0;
    }
    .map-content .map-popup .popup-item:last-child{
        border-bottom: none;
    }
    .map-box{
        display: flex;
        align-items: center;
        padding: 10px 0 3px 0;
    }
    .label{
        font-size: 15px;
    }

    /deep/ .amap-marker-label{
        position:absolute;

        z-index:2;

        border-radius: 5px;

        border:1px solid #cccccc;

        background-color:#00a0e9;

        white-space:nowrap;

        cursor:default;

        padding:3px;

        font-size:12px;

        line-height:14px;

        color:#fff;

        margin-left:-2px;

    }
</style>