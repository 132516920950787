<template>
    <div>
        <!-- 面包屑导航区域 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>预约单</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 卡片视图区域 -->
        <el-card>
            <el-row :gutter="30">
                <el-col :span="9">
                    <el-input v-model="orderInfo.outerId" placeholder="请输入订单号">
                        <el-button slot="append" icon="el-icon-search" @click="serach()"></el-button>
                    </el-input>
                </el-col>
            </el-row>
        </el-card>
        <!-- 订单列表数据 -->
        <el-table :data="reservationList" style="width: 100%;padding:24px;" stripe>
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item label="品牌车型">
                            <span>{{ props.row.brand_name }} {{ props.row.car_name }}</span>
                        </el-form-item>
                        <el-form-item label="预约安装时间">
                            <span>{{ props.row.expect_time }}</span>
                        </el-form-item>
                        <el-form-item label="预约人电话">
                            <span>{{ props.row.mobile }}</span>
                        </el-form-item>
                        <el-form-item label="门店接单时间">
                            <span>{{ props.row.merch_time }}</span>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column label="订单号" prop="outerId" align="center">
            </el-table-column>
            <el-table-column label="核销码" align="center">
                <template slot-scope="scope">
                    【{{scope.row.ordernew.code}}】
                </template>
            </el-table-column>
            <el-table-column label="商品【规格】" v-html align="center">
                <template slot-scope="scope">
                    <span v-html="scope.row.itemTitle"></span>
                    <div>【{{scope.row.skuProperties}}】</div>
                </template>
            </el-table-column>
            <el-table-column label="数量" prop="amount" align="center">
            </el-table-column>
            <el-table-column label="预约时间" prop="expect_time" align="center">
            </el-table-column>
            <el-table-column label="订单状态" prop="status" align="center">
                <template slot-scope="scope" >
                    <el-tag type="warning" v-if="scope.row.status === 0">待接单</el-tag>
                    <el-tag type="primary" v-else-if="scope.row.status === 1">已接单</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.status === 2">已拒绝</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="350px">
                <template slot-scope="scope" >
                    <el-button icon="el-icon-edit" size="mini" type="warning" @click="showReservationDialog(scope.row.merchid,scope.row.id,scope.row.superior_merchid,scope.row.status,scope.row.consume)">查看/修改门店</el-button>
                    <el-button v-if="scope.row.status!='1'" icon="el-icon-s-promotion" size="mini" type="primary" @click="updataMerchStatus(scope.row.merchid,scope.row.id,scope.row.superior_merchid,scope.row.status,scope.row.consume)">帮门店接单</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="orderInfo.page" :page-sizes="[orderInfo.pagesize]"
                       layout="total, sizes, prev, pager, next, jumper" :total="orderInfo.total">
        </el-pagination>
        <!-- 修改修改地址的对话框 -->
        <el-dialog title="预约门店信息" :visible.sync="reservationVisible" width="50%" @close="reservationDialogClosed">
            <!-- 内容主体区域 -->
            <el-form  ref="reservationFormRef" label-width="150px">
                <el-form-item label="安装区域：" class="region" label-width="150px">
                    <el-col :span="24">
                        <el-cascader :options="addressData" :disabled="false" @change="handleAddress" size="mini"
                                     v-model="addressSelections" placeholder="请选择省市区" ref="cascader" popper-class="myascader"></el-cascader>
                    </el-col>
                </el-form-item>
                <el-form-item label="安装门店：" label-width="150px">
                    <el-col :span="24" align="left" style="margin-bottom: 10px;">
                        <el-button @click="getMerchList('',chooseMerch.province,chooseMerch.city,chooseMerch.area)"  type="primary" size="small">选择门店</el-button>
                        <div v-if="merchInfo.total=='0' && chooseMerch.province" style="color:red;font-size: .5rem;">*当前区域无可选门店，建议更换区域</div>
                    </el-col>
                    <div>
                        <el-descriptions direction="vertical" :column="2" border>
                            <el-descriptions-item label="门店">{{chooseMerch.merchname}}</el-descriptions-item>
                            <el-descriptions-item label="电话">{{chooseMerch.contact_phone}}</el-descriptions-item>
                            <el-descriptions-item label="地址">{{chooseMerch.address}}</el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form-item>
                <el-form-item label="服务商：" label-width="150px">
                    <el-col :span="24" align="left" style="margin-bottom: 10px;">
                        <el-button @click="getSuperMerchList('')"  type="primary" size="small">选择服务商</el-button>
                    </el-col>
                    <div>
                        <el-descriptions direction="vertical" :column="2" border>
                            <el-descriptions-item label="门店">{{superMerch.merchname}}</el-descriptions-item>
                            <el-descriptions-item label="电话">{{superMerch.mobile}}</el-descriptions-item>
                            <el-descriptions-item label="地址">{{superMerch.address}}</el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="reservationVisible = false">取 消</el-button>
                <el-button type="primary" @click="updataMerch()">修 改</el-button>
            </span>
        </el-dialog>
        <!-- 选择门店的对话框 -->
        <el-dialog title="安装门店" :visible.sync="merchDialogVisible" width="50%" @close="setMerchDialogClosed">
            <el-table :data="merchData" border style="margin: 5px auto;" max-height="500">
                <el-table-column prop="logo" label="安装门店" align="left">
                    <template slot-scope="scope">
                        <div style="font-size: .9rem;font-weight:600;">{{scope.row.merchname}}</div>
                        <div style="color:#888;font-size: .7rem;">{{scope.row.address}}</div>
                        <div style="color: #409EFF;font-size: .7rem;">{{scope.row.contact_phone}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" min-width="35%">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="setMerchChoose(scope.row.id,scope.row.merchname,scope.row.contact_phone,scope.row.address)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--            <el-pagination @size-change="sizeChange" @current-change="currentChange"-->
            <!--                           :current-page="page" :page-sizes="pageSizes" :pager-count="2"-->
            <!--                           layout="total, prev, pager, next, jumper" :total="total">-->
            <!--            </el-pagination>-->
        </el-dialog>
        <!-- 选择门店的对话框 -->
        <el-dialog title="上级服务商" :visible.sync="superMerchDialogVisible" width="50%" @close="setSuperMerchDialogClosed">
            <el-row :gutter="30">
                <el-col :span="15">
                    <el-input placeholder="请输入内容" v-model="merchname">
                        <el-button slot="append" icon="el-icon-search" @click="getSuperMerchList('')"></el-button>
                    </el-input>
                </el-col>
            </el-row>
            <el-table :data="superMerchData" border style="margin: 5px auto;" max-height="500">
                <el-table-column prop="logo" label="服务商" align="left">
                    <template slot-scope="scope">
                        <div style="font-size: .9rem;font-weight:600;">{{scope.row.merchname}}</div>
                        <div style="color:#888;font-size: .7rem;">{{scope.row.address}}</div>
                        <div style="color: #409EFF;font-size: .7rem;">{{scope.row.mobile}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" min-width="35%">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="setSuperMerchChoose(scope.row.id,scope.row.merchname,scope.row.mobile,scope.row.address)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--            <el-pagination @size-change="sizeChange" @current-change="currentChange"-->
            <!--                           :current-page="page" :page-sizes="pageSizes" :pager-count="2"-->
            <!--                           layout="total, prev, pager, next, jumper" :total="total">-->
            <!--            </el-pagination>-->
        </el-dialog>
    </div>
</template>

<script>
    import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data';
    export default {
        name: "ReservationList",
        data() {
            return {
                //当前选中的订单id
                id:'',
                // 订单分页数据
                orderInfo: {
                    query: '',
                    page: 1,
                    pagesize: 20,
                    total:0,
                    outerId:''
                },
                // 门店分页数据
                merchInfo: {
                    query: '',
                    page: 1,
                    pagesize: 10000,
                    total:0
                },
                // 订单分页数据
                superMerchInfo: {
                    query: '',
                    page: 1,
                    pagesize: 10,
                    total:0
                },

                //地址信息
                addressData: regionData,
                addressSelections: [],
                reservationList: [],
                reservationVisible: false,
                imgHost:'https://cdn.naoled.cn/',
                reservation:[],
                chooseMerch:{
                    //选中的门店名
                    merchname:'',
                    //选中的门店ID
                    merchid:'',
                    //选中的门店联系方式
                    contact_phone:'',
                    //选中的门店地址
                    address:'',
                    //选中省市区
                    province:'',
                    city:'',
                    area:''
                },
                superMerch:{
                    //选中的门店名
                    merchname:'',
                    //选中的门店ID
                    merchid:'',
                    //选中的门店联系方式
                    contact_phone:'',
                    //选中的门店地址
                    address:'',
                },
                //搜索服务商
                merchname:'',
                // 选中的订单状态
                status:'',
                //门店数据
                merchData:[],
                //门店数据
                superMerchData:[],
                //控制门店dialog显示
                merchDialogVisible:false,
                //控制服务商dialog显示
                superMerchDialogVisible:false,
                //当前选中门店的核销数据
                consume:''
            }
        },
        created() {
            this.getReservationList()
        },
        methods: {
            async getReservationList() {
                const {data: result} = await this.$http.get('reservation', {
                    params: this.orderInfo
                }).catch((fail)=>{
                    return this.$message.error(fail.response.data.message);
                });
                if (result.status !== 0) {
                    return this.$message.error('获取数据列表失败');
                }
                this.orderInfo.total=result.result.total;
                this.reservationList = result.result.data;
            },
            handleSizeChange(newSize) {
                this.orderInfo.pagesize = newSize;
                this.getReservationList();
            },
            handleCurrentChange(newPage) {
                this.orderInfo.page = newPage;
                this.getReservationList();
            },
            // 展示查看/修改门店的对话框
            showReservationDialog(merchid,id,superid,status,consume) {
                this.id=id;
                this.chooseMerch.merchid=merchid;
                this.superMerch.merchid=superid;
                this.status=status;
                this.consume=consume;
                this.getSuperMerchList(superid);
                this.getMerchList(merchid);
                this.reservationVisible = true;
            },
            reservationDialogClosed() {
                this.reservationVisible = false;
                this.chooseMerch.province='';
                this.chooseMerch.city='';
                this.chooseMerch.area='';
                this.addressSelections=[];
                this.chooseMerch.merchname='';
                this.chooseMerch.merchid='';
                this.chooseMerch.contact_phone='';
                this.chooseMerch.address='';
            },
            //省市区选择器选择
            handleAddress() {
                let deliveryAreaName=this.$refs["cascader"].getCheckedNodes()[0].pathLabels.join(',');
                deliveryAreaName=deliveryAreaName.split(',');
                this.chooseMerch.province=deliveryAreaName[0];
                this.chooseMerch.city=deliveryAreaName[1];
                this.chooseMerch.area=deliveryAreaName[2];
                this.getMerchList('',deliveryAreaName[0],deliveryAreaName[1],deliveryAreaName[2]);
            },
            //获取门店列表（用于查服务商）
            async getSuperMerchList(id) {
                let merchname=this.merchname;
                if(id){
                    const {data: result} = await this.$http.get('ewei_shop_merch/'+id, {
                    }).catch((fail)=>{
                        return this.$message.error(fail.response.data.message);
                    });
                    if (result.status !== 0) {
                        return this.$message.error('获取服务商列表失败');
                    }
                    this.superMerch.merchname=result.result.merchname;
                    this.superMerch.merchid=id;
                    this.superMerch.mobile=result.result.mobile;
                    this.superMerch.address=result.result.address;
                }else{
                    if(merchname){
                        const {data: result} = await this.$http.get('ewei_shop_merch?superior=1&merchname='+merchname, {
                        }).catch((fail)=>{
                            return this.$message.error(fail.response.data.message);
                        });
                        if (result.status !== 0) {
                            return this.$message.error('获取服务商列表失败');
                        }
                        this.superMerchData=result.result;
                        this.superMerchDialogVisible=true;
                        this.superMerchInfo.total=result.result.length;
                    }else{
                        const {data: result} = await this.$http.get('ewei_shop_merch?superior=1', {
                        }).catch((fail)=>{
                            return this.$message.error(fail.response.data.message);
                        });
                        if (result.status !== 0) {
                            return this.$message.error('获取服务商列表失败');
                        }
                        this.superMerchData=result.result;
                        this.superMerchDialogVisible=true;
                        this.superMerchInfo.total=result.result.length;
                    }
                }
            },
            //获取门店列表
            async getMerchList(id,province,city,area) {
                if(id){
                    const {data: result} = await this.$http.get('ewei_shop_merch/'+id, {
                    }).catch((fail)=>{
                        return this.$message.error(fail.response.data.message);
                    });
                    if (result.status !== 0) {
                        return this.$message.error('获取门店列表失败');
                    }
                    this.chooseMerch.merchname=result.result.merchname;
                    this.chooseMerch.merchid=result.result.id;
                    this.chooseMerch.contact_phone=result.result.contact_phone;
                    this.chooseMerch.address=result.result.address;
                }else{
                    if(province){
                        const {data: result} = await this.$http.get('ewei_shop_merch?province='+province+'&city='+city+'&area='+area, {
                        }).catch((fail)=>{
                            return this.$message.error(fail.response.data.message);
                        });
                        if (result.status !== 0) {
                            return this.$message.error('获取门店列表失败');
                        }
                        this.merchData=result.result;
                        this.merchDialogVisible=true;
                        this.merchInfo.total=result.result.length;
                    }else{
                        return this.$message.error('请先选择安装区域');
                    }
                }
            },
            //关闭选择门店dialog时执行
            setMerchDialogClosed(){
                this.merchDialogVisible = false;
                this.merchInfo.page = 1;
            },
            //关闭选择门店dialog时执行
            setSuperMerchDialogClosed(){
                this.superMerchDialogVisible = false;
                this.superMerchInfo.page = 1;
            },
            //选中的门店数据
            setMerchChoose(id,merchname,contact_phone,address){
                this.chooseMerch.merchid=id;
                this.chooseMerch.merchname=merchname;
                this.chooseMerch.contact_phone=contact_phone;
                this.chooseMerch.address=address;
                this.merchDialogVisible = false;
            },
            //选中的服务商数据
            setSuperMerchChoose(id,merchname,mobile,address){
                this.superMerch.merchid=id;
                this.superMerch.merchname=merchname;
                this.superMerch.mobile=mobile;
                this.superMerch.address=address;
                this.superMerchDialogVisible = false;
            },
            async updataMerch(){
                let merchid=this.chooseMerch.merchid;
                let superior_merchid=this.superMerch.merchid;
                let status=this.status;
                let consume=this.consume;
                if(consume){
                    if(consume.commission.status>0 || consume.status>0){
                        return this.$message.error('该预约单已核销或已分佣，无法修改');
                    }
                }

                const {data: result} = await this.$http.put('reservation/'+this.id+'?merchid='+merchid+'&superior_merchid='+superior_merchid+'&status='+status, {
                }).catch((fail)=>{
                    return this.$message.error(fail.response.data.message);
                });
                if (result.status == 0) {
                    this.reservationVisible=false;
                    setTimeout(() => {
                        this.$router.go(0);
                    },500)
                    return this.$message.success('修改预约门店成功');
                }else{
                    this.reservationVisible=false;
                    return this.$message.error('修改预约门店失败');
                }
            },
            async updataMerchStatus(merchid,id,superid,consume){
                //弹框提示是否删除
                const confirmResult = await this.$confirm('确定帮门店接单吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).catch((error) => error);
                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消接单');
                }
                if(consume){
                    if(consume.commission.status>0 || consume.status>0){
                        return this.$message.error('该预约单已核销或已分佣，无法接单');
                    }
                }
                const {data: result} = await this.$http.put('reservation/'+id+'?merchid='+merchid+'&superior_merchid='+superid+'&status=1', {
                }).catch((fail)=>{
                    return this.$message.error(fail.response.data.message);
                });
                if (result.status == 0) {
                    setTimeout(() => {
                        this.$router.go(0);
                    },500);
                    return this.$message.success('接单成功');
                }else{
                    return this.$message.error('接单失败');
                }
            },
            //查找订单
            async serach(){
                this.getReservationList();
            },
        },
        computed:{

        }

    }
</script>

<style scoped>
    .bdtop {
        border-top: 1px solid #eee;
    }
    .bdbottom {
        border-bottom: 1px solid #eee;
    }
    .img_goods{
        width: 90%;
    }
    .title_goods{
        color: #409EFF;
        padding: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 40px;
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-left: 100px;
        margin-bottom: 0;
        width: 100%;
    }
</style>
<style>
    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 120px;
        color: #99a9bf;
    }
</style>