<template>
    <div>
        <!-- 面包屑导航区域 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/orders' }">订单列表</el-breadcrumb-item>
            <el-breadcrumb-item>新建订单</el-breadcrumb-item>
        </el-breadcrumb>
        <!--        卡片视图-->
        <el-card>
            <el-col :span="18">
                <el-form :model="trade" :rules="tradeRules" ref="tradeRef" label-width="80px">
                    <el-form-item label="交易编号" prop="tid">
                        <el-input v-model="trade.tid" :span="9" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="交易来源" prop="source">
                        <el-radio-group v-model="trade.source" >
                            <el-radio v-for="item in sources" :key="item.value" :value="item.value" :label="item.value" border>{{item.label}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="商品金额" prop="total_fee">
                        <el-input v-model="trade.total_fee" :span="9" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="实付金额" prop="payment">
                        <el-input v-model="trade.payment" :span="9" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="创建时间" prop="created">
                        <el-date-picker
                                :picker-options="pickerOptionsStart"
                                clearable
                                size="small"
                                style="width: 200px"
                                v-model="trade.created"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetime"
                                placeholder="选择订单创建时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="支付时间" prop="pay_time">
                        <el-date-picker
                                :picker-options="pickerOptionsEnd"
                                clearable
                                size="small"
                                style="width: 200px"
                                v-model="trade.pay_time"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetime"
                                placeholder="选择订单支付时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="子订单" prop="orders">
                        <el-col :span="4" style="margin-bottom: 20px;">
                            <el-button type="warning" @click="setOrdersDialogOpened">新建子订单</el-button>
                        </el-col>
                        <!-- 订单列表数据 -->
                        <el-table :data="orders" border stripe>
                            <el-table-column prop="pic_path" label="图片" align="center">
                                <template slot-scope="scope">
                                    <el-image style="width: 40px; height: 40px;" :src="scope.row.pic_path" fit="cover" ></el-image>
                                </template>
                            </el-table-column>
                            <el-table-column prop="title" label="商品" :show-overflow-tooltip="true" align="center">
                            </el-table-column>
                            <el-table-column prop="sku_properties_name" label="规格" :show-overflow-tooltip="true" align="center">
                            </el-table-column>
                            <el-table-column prop="num" label="数量" align="center">
                            </el-table-column>
                            <el-table-column label="子订单编号" prop="oid" align="center"></el-table-column>
                            <el-table-column prop="total_fee" label="应付金额" align="center">
                            </el-table-column>
                            <el-table-column prop="payment" label="实付金额" align="center">
                            </el-table-column>
                            <el-table-column prop="commission_price" label="分佣金额" align="center">
                            </el-table-column>
                            <el-table-column prop="is_service_order" label="是否服务订单" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.is_service_order==='1'">是</span>
                                    <span v-else>否</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                    <el-button icon="el-icon-delete" size="mini" type="danger" @click="deleteOrder(scope.row.goods_id)"></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item label="买家留言" prop="buyer_message">
                        <el-input v-model="trade.buyer_message" :span="9" clearable></el-input>
                    </el-form-item>
                </el-form>
                <el-col :span="24" align="center" style="margin:30px auto;">
                    <el-button type="primary" @click="submit('tradeRef')">提交</el-button>
                </el-col>
            </el-col>
        </el-card>
        <!-- 新建子订单的对话框 -->
        <el-dialog title="新建子订单" :visible.sync="addOrdersDialogVisible" width="50%" @close="setOrdersDialogClosed">
            <el-form :model="NewOrders" :rules="orderRules" ref="orderRef" label-width="100px">
                <el-form-item label="子订单编号" prop="oid">
                    <el-input v-model="NewOrders.oid" :span="9" clearable></el-input>
                </el-form-item>
                <el-form-item label="子订单门店">
                    <el-col :span="4">
                        <el-button type="primary" @click="setMerchDialogOpened">选择门店</el-button>
                    </el-col>
                </el-form-item>
                <el-form-item v-if="NewOrders.third_store_id" label="商品名称规格" prop="sku_properties_name">
                    <span>{{NewOrders.third_store}}[ID:{{NewOrders.third_store_id}}]</span>
                </el-form-item>
                <el-form-item label="子订单商品">
                    <el-col :span="4">
                        <el-button type="warning" @click="setGoodsDialogOpened">选择商品</el-button>
                    </el-col>
                </el-form-item>
                <el-form-item v-if="NewOrders.sku_properties_name" label="商品名称规格" prop="sku_properties_name">
                    <span v-if="NewOrders.sku_properties_name=='无规格'">{{NewOrders.title}}</span>
                    <span v-else>{{NewOrders.title}}[{{NewOrders.sku_properties_name}}]</span>
                </el-form-item>
                <el-form-item label="应付金额" prop="total_fee">
                    <el-input type="number" v-model.number="NewOrders.total_fee" :span="9" clearable></el-input>
                </el-form-item>
                <el-form-item label="实付金额" prop="payment">
                    <el-input type="number" v-model.number="NewOrders.payment" :span="9" clearable></el-input>
                </el-form-item>
                <el-form-item label="分佣金额" prop="commission_price">
                    <el-input v-model="NewOrders.commission_price" :span="9" clearable></el-input>
                </el-form-item>
                <el-form-item v-if="NewOrders.num" label="商品数量" prop="num">
                    <el-input-number v-model="NewOrders.num" :step="1" :min="1" :max="99999" placeholder="数量" size="small" @change="changeVal"></el-input-number>
                </el-form-item>
                <el-form-item label="服务订单" prop="is_service_orders">
                    <el-tooltip content="是否服务订单" placement="top">
                        <el-switch
                                v-model="NewOrders.is_service_order"
                                active-value="1"
                                inactive-value="0">
                        </el-switch>
                    </el-tooltip>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                       <el-button @click="addOrdersDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addOrders('orderRef')">确定</el-button>
            </span>
        </el-dialog>
        <!-- 选择商品的对话框 -->
        <el-dialog title="选择商品" :visible.sync="addGoodsDialogVisible" width="40%" @close="setGoodsDialogClosed">
            <el-table :data="tableData" border style="margin: 15px auto;">
                <el-table-column prop="thumb" label="图片" min-width="10%" align="center">
                    <template slot-scope="scope">
                        <el-image style="width: 80px; height: 80px;" :src="scope.row.thumb" fit="cover" ></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="商品" min-width="10%" :show-overflow-tooltip="true" align="center">
                </el-table-column>
                <el-table-column prop="option" label="规格" min-width="13%" align="center" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div v-if="scope.row.sku_id">
                            <el-select :popper-append-to-body="false" v-model="scope.row.sku_properties_name"  @change="(value)=>
               {changeCell(value, scope.row)}" placeholder="选择规格">
                                <el-option
                                        :title="item.title"
                                        v-for="(item,index) in scope.row.option"
                                        :key="item.id"
                                        :label="item.title"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div v-else>{{scope.row.sku_properties_name}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="7%" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="setGoodsChoose(scope.row.id)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="sizeChange" @current-change="currentChange"
                           :current-page="page" :page-size="size" :page-sizes="pageSizes"
                           layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-dialog>
        <!-- 选择门店的对话框 -->
        <el-dialog title="选择门店" :visible.sync="addMerchDialogVisible" width="45%" @close="setMerchDialogClosed">
            <el-table :data="tableData2" border style="margin: 15px auto;">
                <el-table-column prop="id" label="ID" min-width="7%" align="center">
                </el-table-column>
                <el-table-column prop="logo" label="门头照片" min-width="12%" align="center">
                    <template slot-scope="scope">
                        <el-image style="width: 80px; height: 80px;" :src="scope.row.logo" fit="cover" ></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="merchname" label="门店名" min-width="12%" :show-overflow-tooltip="true" align="center">
                </el-table-column>
                <el-table-column prop="realname" label="负责人" min-width="7%" :show-overflow-tooltip="true" align="center">
                </el-table-column>
                <el-table-column label="操作" min-width="7%" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="setMerchChoose(scope.row.id)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="sizeChange2" @current-change="currentChange2"
                           :current-page="page2" :page-size="size2" :page-sizes="pageSizes2"
                           layout="total, sizes, prev, pager, next, jumper" :total="total2">
            </el-pagination>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "CreateOrder",
        data() {
            return{
                trade:{ //交易订单
                    tid:'',
                    total_fee:'',
                    payment:'',
                    buyer_message:'',
                    source:'1',
                    created:'',
                    pay_time:'',
                },
                orders:[],//交易子订单
                goodsData:[],//商品数据
                merchData:[],//门店数据
                NewOrders:{//当前新建子订单
                    oid:''
                },
                tableData:[],//dialog商品表格数据（显示数组）
                tableData2:[],//dialog门店表格数据（显示数组）
                addOrdersDialogVisible:false,//控制新建子订单dialog显示
                addGoodsDialogVisible:false,//控制新建子订单商品dialog显示
                addMerchDialogVisible:false,//控制新建子门店dialog显示
                tradeRules:{
                    tid: [
                        { required: true, message: '请输入交易订单编号', trigger: 'blur' },
                        { pattern: /^[^\u4e00-\u9fa5]+$/, message: '交易订单编号不允许输入中文',trigger: 'blur'},
                        { pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '交易订单编号不允许输入空格等特殊符号',trigger: 'blur'},
                        { pattern: /[0-9a-zA-Z]{1,6}/, message: '交易订单编号只可以输入数字和字母',trigger: 'blur'},
                        { min: 8, max: 50, message: '交易订单编号长度在 8 到 50个字符', trigger: 'blur' }
                    ],
                    total_fee:[
                        { required: true, message: '请输入交易订单应付金额', trigger: 'blur' },
                        { pattern: /^(([1-9]\d*)|0)(\.\d{1,2})?$/, message: '请正确填写交易订单应付金额',trigger: 'blur'},
                        { min: 1, max: 50, message: '交易订单应付金额长度在 1 到 50个字符', trigger: 'blur' }
                    ],
                    payment:[
                        { required: true, message: '请输入交易订单实付金额', trigger: 'blur' },
                        { pattern: /^(([1-9]\d*)|0)(\.\d{1,2})?$/, message: '请正确填写交易订单实付金额',trigger: 'blur'},
                        { min: 1, max: 50, message: '交易订单实付金额长度在 1 到 50个字符', trigger: 'blur' }
                    ]
                },
                orderRules:{
                    tid: [
                        { required: true, message: '请输入子订单编号', trigger: 'blur' },
                        { pattern: /^[^\u4e00-\u9fa5]+$/, message: '子订单编号不允许输入中文',trigger: 'blur'},
                        { pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '子订单编号不允许输入空格等特殊符号',trigger: 'blur'},
                        { pattern: /[0-9a-zA-Z]{1,6}/, message: '子订单编号只可以输入数字和字母',trigger: 'blur'},
                        { min: 8, max: 50, message: '子订单编号长度在 8 到 50个字符', trigger: 'blur' }
                    ],
                    total_fee:[
                        { required: true, message: '请输入子订单应付金额', trigger: 'blur' },
                        { pattern: /^(([1-9]\d*)|0)(\.\d{1,2})?$/, message: '请正确填写子订单应付金额',trigger: 'blur'}
                    ],
                    payment:[
                        { required: true, message: '请输入子订单实付金额', trigger: 'blur' },
                        { pattern: /^(([1-9]\d*)|0)(\.\d{1,2})?$/, message: '请正确填写子订单实付金额',trigger: 'blur'}
                    ],
                    commission_price:[
                        { required: true, message: '请输入子订单分佣金额', trigger: 'blur' },
                        { pattern: /^(([1-9]\d*)|0)(\.\d{1,2})?$/, message: '请正确填写子订单分佣金额',trigger: 'blur'}
                    ]
                },
                sources:[
                    {value:'0',label:'小程序人工创建'},
                    {value:'1',label:'天猫人工创建'},
                    {value:'2',label:'天猫自动'},
                    {value:'3',label:'抖音人工创建'}
                ],
                //时间限制，符合日期先后顺序的实际情况
                pickerOptionsStart: {
                    disabledDate: (time) => {
                        if (this.trade.pay_time) {
                            return (
                                time.getTime() > Date.now() - 8.64e6 ||
                                //开始日期要在选择的结束日期之前,大于结束时间的日期不能选
                                time.getTime() > new Date(this.trade.pay_time).getTime()
                            );
                        }
                        return time.getTime() > Date.now() - 8.64e6; //今天及之前
                    }
                },
                pickerOptionsEnd: {
                    disabledDate: (time) => {
                        if (this.trade.created) {
                            return (
                                time.getTime() > Date.now() - 8.64e6 ||
                                //结束日期要在选择的开始日期之后,小于开始时间的日期不能选
                                time.getTime() < new Date(this.trade.created).getTime()
                            );
                        }
                        return time.getTime() > Date.now() - 8.64e6; //今天及之前
                    }
                },
                page: 1, //第几页
                size: 5, //一页多少条
                total: 0, //总条目数
                pageSizes: [5, 10, 20, 50, 100, 200, 300, 400, 500, 1000], //可选择的一页多少条
                page2: 1, //第几页
                size2: 5, //一页多少条
                total2: 0, //总条目数
                pageSizes2: [5, 10, 20, 50, 100, 200, 300, 400, 500, 1000], //可选择的一页多少条
                imgHost:'https://cdn.naoled.cn/',
                option:''//当前选中的规格id
            }
        },
        methods:{
            //获取商品列表
            async getGoodsList() {
                const {data: result} = await this.$http.get('ewei_shop_goods', {
                });
                if (result.status !== 0) {
                    return this.$message.error('获取订单列表失败');
                }
                this.goodsData=result.result;
                this.goodsData.forEach(item=>{
                    if (!item.thumb.includes(this.imgHost)) {
                        item.thumb=this.imgHost+item.thumb;
                    }
                    if(item.option.length>0){
                        item.sku_id=item.option[0].id;
                        item.sku_properties_name=item.option[0].title;
                    }else{
                        item.sku_id='';
                        item.sku_properties_name='无规格';
                    }
                });
                this.getTabelData();
            },
            //获取商品列表
            async getMerchList() {
                const {data: result} = await this.$http.get('ewei_shop_merch', {
                });
                if (result.status !== 0) {
                    return this.$message.error('获取门店列表失败');
                }
                this.merchData=result.result;
                this.merchData.forEach(item=>{
                    if (!item.logo.includes(this.imgHost)) {
                        item.logo=this.imgHost+item.logo;
                    }
                });
                this.getTabelData2();
            },
            //点击新建子订单按钮时执行
            setOrdersDialogOpened(){
                this.NewOrders={oid:''};
                this.addOrdersDialogVisible = true;
            },
            //点击选择商品按钮时执行
            setGoodsDialogOpened(){
                if(!this.NewOrders.third_store_id){
                    this.$message.error('请先选择门店');
                    return;
                }
                this.getGoodsList();
                this.addGoodsDialogVisible = true;
            },
            //点击选择门店按钮时执行
            setMerchDialogOpened(){
                this.getMerchList();
                this.addMerchDialogVisible = true;
            },
            //关闭新建子订单dialog时执行
            setOrdersDialogClosed(){

            },
            //关闭选择商品dialog时执行
            setGoodsDialogClosed(){
                this.getGoodsList();
                this.page = 1;
            },
            //关闭选择门店dialog时执行
            setMerchDialogClosed(){
                this.getMerchList();
                this.page = 1;
            },
            //商品获取表格数据，自行分页（splice）
            getTabelData() {
                let data=JSON.parse(JSON.stringify(this.goodsData));
                this.tableData  = data.splice(
                    (this.page - 1) * this.size,
                    this.size
                );
                this.total=this.goodsData.length;
            },
            //门店获取表格数据，自行分页（splice）
            getTabelData2() {
                let data=JSON.parse(JSON.stringify(this.merchData));
                this.tableData2  = data.splice(
                    (this.page2 - 1) * this.size2,
                    this.size2
                );
                this.total2=this.merchData.length;
            },
            //商品page改变时的回调函数，参数为当前页码
            currentChange(val) {
                this.page = val;
                this.getTabelData();
            },
            //商品size改变时回调的函数，参数为当前的size
            sizeChange(val) {
                this.size = val;
                this.page = 1;
                this.getTabelData();
            },
            //门店page改变时的回调函数，参数为当前页码
            currentChange2(val) {
                this.page2 = val;
                this.getTabelData2();
            },
            //门店size改变时回调的函数，参数为当前的size
            sizeChange2(val) {
                this.size2 = val;
                this.page2 = 1;
                this.getTabelData2();
            },
            changeVal() {
                this.$forceUpdate();//解决点击计数器失效问题
            },
            //点击下拉菜单选择商品规格
            changeCell (value, row) {
                this.tableData.forEach(item => {
                    // 找到parentKey是prop的列 获取列的数组 将row中的这些列改值
                   if(row.id === item.id){
                       row.option.forEach(item2 =>{
                           if(value === item2.id){
                               item.sku_id = item2.id;
                               item.sku_properties_name=item2.title;
                           }
                       });
                   }
                });
            },
            //选中子订单门店
            setMerchChoose(id){
                this.tableData2.forEach(item=>{
                    if(id===item.id){
                        console.log(item)
                        this.NewOrders.third_store=item.merchname;
                        this.NewOrders.third_store_id=item.id;
                        this.NewOrders.groupid=item.groupid;
                        if(this.NewOrders.commission && this.NewOrders.commission.length>0){
                            this.NewOrders.commission.forEach(item=>{
                                if(this.NewOrders.groupid===item.groupid){
                                    if(item.merch_commission_rate!=='0.00'){
                                        this.NewOrders.commission_price=this.NewOrders.payment*item.merch_commission_rate*0.01;
                                    }else{
                                        this.NewOrders.commission_price=item.merch_commission_pay;
                                    }
                                }
                            })
                        }else{
                            this.NewOrders.commission_price=0;
                        }
                        this.addMerchDialogVisible = false;
                    }
                });
            },
            //选中子订单商品
            setGoodsChoose(id){
                this.tableData.forEach(item=>{
                    if(id===item.id){
                        // if(item.payment){
                        //     let validator4=/^(([1-9]\d*)|0)(\.\d{1,2})?$/;
                        //     if(!validator4.test(item.payment)){
                        //         this.$message.error('请正确填写子订单商品实付价');
                        //         return;
                        //     }
                        // }else{
                        //     this.$message.error('请填写子订单商品实付价');
                        //     return;
                        // }
                        console.log(item)
                        this.NewOrders.num=1;
                        this.NewOrders.title = item.title;
                        this.NewOrders.goods_id = item.id;
                        this.NewOrders.sku_id = item.sku_id;
                        this.NewOrders.pic_path = item.thumb;
                        this.NewOrders.sku_properties_name=item.sku_properties_name;
                        this.NewOrders.total_fee=parseFloat(item.marketprice);
                        this.NewOrders.payment=parseFloat(item.marketprice);
                        this.NewOrders.is_service_order='1';
                        this.NewOrders.commission=item.commission;
                        if(this.NewOrders.groupid){
                            if(item.commission.length>0){
                                item.commission.forEach(item2=>{
                                    if(this.NewOrders.groupid===item2.groupid){
                                        if(item2.merch_commission_rate!=='0.00'){
                                            this.NewOrders.commission_price=item.marketprice*item2.merch_commission_rate*0.01;
                                        }else{
                                            this.NewOrders.commission_price=item2.merch_commission_pay;
                                        }
                                    }
                                })
                            }else{
                                this.NewOrders.commission_price=0;
                            }
                        }
                        this.addGoodsDialogVisible = false;
                    }
                });
            },
            //删除子订单
            deleteOrder(id){
                for(let i in this.orders){
                    if(id== this.orders[i].goods_id){
                        this.orders.splice(i, 1);
                        this.$message.success('删除子订单商品成功');
                        return;
                    }
                }
            },
            //创建子订单数据
            addOrders(formName){
                this.$refs[formName].validate(async(valid) => {
                    if (valid) {
                        if(!this.NewOrders.total_fee){
                            this.$message.error('请填写子订单应付金额');
                            return false;
                        }
                        if(!this.NewOrders.payment){
                            this.$message.error('请填写子订单实付金额');
                            return false;
                        }
                        if(!this.NewOrders.third_store_id){
                            this.$message.error('请选择子订单门店');
                            return false;
                        }
                        if(!this.NewOrders.goods_id){
                            this.$message.error('请选择子订单商品');
                            return false;
                        }
                        this.orders.push(this.NewOrders);
                        console.log(this.orders)
                        this.addOrdersDialogVisible=false;
                        this.$message.success('新建子订单成功');
                    } else {
                        this.$message.error('请正确填写子订单内容');
                        return false;
                    }
                });
            },
            //提交订单数据
            submit(formName){
                this.$refs[formName].validate(async(valid) => {
                    if (valid) {
                        //验证成功，发起新建订单的网络请求
                        this.$http.post('trade',{trade:this.trade,orders:this.orders}).then(({res})=>{
                            if (res.status == 0) {
                                this.$message.success('新建订单成功');
                                this.$router.push('/orders');
                            }
                        }).catch((fail)=>{
                            console.log(fail);
                            this.$message.error(fail.response.data.message);
                            return;
                        });
                    } else {
                        this.$message.error('新建订单失败');
                        return false;
                    }
                });
            }
        },
        created() {
            this.getGoodsList();
            this.getMerchList();
        },
    }
</script>

<style scoped>
    .flex_align_center{
        display: flex;
        align-items: center;
    }
</style>