<template>
    <div>
        <!-- 面包屑导航区域 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商品管理</el-breadcrumb-item>
            <el-breadcrumb-item>商品列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 卡片视图区域 -->
        <el-card>
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-input placeholder="请输入内容">
                        <el-button slot="append" v-model="queryInfo.itemTitle" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="createGoods">新建商品</el-button>
                </el-col>
            </el-row>
        </el-card>
        <!-- 订单列表数据 -->
        <el-table :data="GoodsList" bGoods stripe>
            <el-table-column label="商品ID" prop="itemId" align="center"></el-table-column>
            <el-table-column label="商品名称" prop="itemTitle" align="center"></el-table-column>
            <el-table-column label="门店佣金" prop="commission" align="center"></el-table-column>
            <el-table-column label="商品描述" prop="skuProperties"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button icon="el-icon-edit" size="mini" type="primary" @click="editGoods(scope.$index,scope.row.id)"></el-button>
                    <el-button icon="el-icon-delete" size="mini" type="danger" @click="deleteGoods(scope.row.id)"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="queryInfo.page" :page-size="queryInfo.pagesize"
                       layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <!-- 修改修改地址的对话框 -->
        <el-dialog title="修改商品" :visible.sync="goodsVisible" width="50%" @close="goodsDialogClosed">
            <!-- 内容主体区域 -->
            <el-form :model="goodsForm" :rules="goodsFormRules" ref="goodsFormRef" label-width="100px">
                <el-form-item label="商品ID" prop="itemId">
                    <el-input v-model="goodsForm.itemId"></el-input>
                </el-form-item>
                <el-form-item label="商品名称" prop="itemTitle">
                    <el-input v-model="goodsForm.itemTitle"></el-input>
                </el-form-item>
                <el-form-item label="门店佣金" prop="commission">
                    <el-input v-model="goodsForm.commission"></el-input>
                </el-form-item>
                <el-form-item label="商品描述" prop="skuProperties">
                    <el-input v-model="goodsForm.skuProperties"></el-input>
                </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="goodsVisible = false">取 消</el-button>
                <el-button type="primary" @click="editGoodsSubmit('goodsFormRef')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import cityData from '../assets/js/cityData.js';
    import db from '../assets/js/db.js';
    export default {
        name: "Goods",
        data() {
            return {
                queryInfo: {
                    query: '',
                    page: 1,
                    pagesize: 10,
                    itemTitle:''
                },
                total: 1,
                GoodsList: [],
                goodsVisible: false,
                goodsForm:{
                },
                //商品数据
                goodsFormRules:{
                    itemId:[
                        {required: true, message: '请输入商品Id', trigger: 'blur'},
                        { pattern: /^[^\u4e00-\u9fa5]+$/, message: '交易订单编号不允许输入中文',trigger: 'blur'},
                        { pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '交易订单编号不允许输入空格等特殊符号',trigger: 'blur'},
                        { pattern: /[0-9a-zA-Z]{1,6}/, message: '交易订单编号只可以输入数字和字母',trigger: 'blur'},
                        {min: 1, max: 20, message: '长度在 1 到 50 个字符', trigger: 'blur'},
                    ],
                    itemTitle:[
                        {required: true, message: '请输入商品名称', trigger: 'blur'},
                        {min: 3, max: 20, message: '长度在 3 到 50 个字符', trigger: 'blur'},
                    ],
                    commission:[
                        { required: true, message: '请输入门店佣金', trigger: 'blur' },
                        { pattern: /^(([1-9]\d*)|0)(\.\d{1,2})?$/, message: '请正确填写门店佣金',trigger: 'blur'},
                        { min: 1, max: 50, message: '门店佣金长度在 1 到 50个字符', trigger: 'blur' }
                    ],
                    skuProperties:[
                        { required: true, message: '请输入商品描述', trigger: 'blur' },
                        { min: 1, max: 50, message: '商品描述长度在 1 到 50个字符', trigger: 'blur' }
                    ]
                },
                cityData:cityData,
                progressVisible: false,
                progressInfo:[],
                db:db,
                imgHost:'https://cdn.naoled.cn/'
            }
        },
        created() {
            this.getGoodsList();
        },
        methods: {
            async getGoodsList() {
                const {data: result} = await this.$http.get('item', {
                    params: this.queryInfo
                });
                if (result.status !== 0) {
                    return this.$message.error('获取订单列表失败');
                }
                this.GoodsList = result.result.data;
                this.total=result.result.total;
                this.queryInfo.pagesize=result.result.per_page;
            },
            handleSizeChange(newSize) {
                this.queryInfo.pagesize = newSize;
                this.getGoodsList();
            },
            handleCurrentChange(newPage) {
                this.queryInfo.page = newPage;
                this.getGoodsList();
            },
            // 展示修改商品的对话框
            editGoods(index,id) {
                this.goodsVisible = true;
                this.goodsForm=this.GoodsList[index];
                this.goodsForm.id=id;
            },
            goodsDialogClosed() {
                this.$refs.goodsFormRef.resetFields();
            },
            editGoodsSubmit(formName){
                this.$refs[formName].validate(async (valid) => {
                    if (!valid) return;
                    const { data: res } = await this.$http.put('item/' + this.goodsForm.id, {
                        itemId: this.goodsForm.itemId,
                        itemTitle: this.goodsForm.itemTitle,
                        skuProperties: this.goodsForm.skuProperties,
                        commission: this.goodsForm.commission
                    });
                    if (res.status == 0) {
                        this.goodsVisible = false;
                        this.$message.success('修改商品成功！');
                        location.reload()
                    }else{
                        return this.$message.error('修改商品失败！');
                    }
                })
            },
            async deleteGoods(id){
                //弹框提示是否删除
                const confirmResult = await this.$confirm('此操作将永久删除该商品, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).catch((error) => error);
                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消删除');
                }
                const { data: res } = await this.$http.delete('item/'+id, {});
                if (res.status == 0) {
                    this.$message.success('删除商品成功');
                    location.reload()
                }else{
                    this.$message.error(res.message);
                }
            },
            createGoods(){
                //指定跳转的地址
                this.$router.replace('/createGoods');
            }
        },
        computed:{

        }

    }
</script>

<style scoped>
    .bdtop {
        bGoods-top: 1px solid #eee;
    }
    .bdbottom {
        bGoods-bottom: 1px solid #eee;
    }
    .img_goods{
        width: 90%;
    }
    .title_goods{
        color: #409EFF;
        padding: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 40px;
    }
</style>