<template>
    <div>
        <!-- 面包屑导航区域 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 卡片视图区域 -->
        <el-card>
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-input placeholder="请输入内容">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="createOrders">新建订单</el-button>
                </el-col>
            </el-row>
        </el-card>
        <!-- 订单列表数据 -->
        <el-table :data="orderList" border stripe>
            <el-table-column type="expand" align="center">
                <template slot-scope="scope">
                    <!-- el-row el-col 实现栅格布局 -->
                    <el-row :class="['bdbottom', index1 === 0 ? 'bdtop' : '']"
                            v-for="(item1,index1) in scope.row.orders" :key="item1.id" type="flex" align="middle">
                        <!-- 渲染一级权限 -->
                        <el-col :span="2" style="margin:0 10px;">
                            <el-row type="flex" justify="center">
                                <img class="img_goods" :src="item1.goods.thumb" alt="" >
                            </el-row>
                        </el-col>
                        <el-col :span="4">
                            <el-row>
                                <span class="title_goods el-submenu__title">{{item1.goods.title}}</span>
                            </el-row>
                            <el-row>
                                <el-tag type="warning">{{item1.sku_properties_name}}</el-tag>
                            </el-row>
                        </el-col>
                        <el-col :span="2">
                            <el-row type="flex" justify="center">
                                <span>￥ {{item1.payment}}</span>
                            </el-row>
                        </el-col>
                        <el-col :span="2">
                            <el-row type="flex" justify="center">
                                <span>{{item1.num}}</span>
                            </el-row>
                        </el-col>
                    </el-row>
                </template>
            </el-table-column>
            <el-table-column label="订单号" prop="tid" align="center"></el-table-column>
            <el-table-column label="商品金额" prop="total_fee" align="center"></el-table-column>
            <el-table-column label="下单时间" prop="created" align="center">
                <template slot-scope="scope">
                    {{ scope.row.created}}
                </template>
            </el-table-column>
            <el-table-column label="付款时间" prop="pay_time" align="center">
                <template slot-scope="scope">
                    {{ scope.row.pay_time}}
                </template>
            </el-table-column>
            <el-table-column label="买家留言" prop="buyer_message"></el-table-column>
<!--            <el-table-column label="是否付款" prop="pay_status">-->
<!--                <template slot-scope="scope">-->
<!--                    <el-tag type="success" v-if="scope.row.pay_status === '1'">已付款</el-tag>-->
<!--                    <el-tag type="danger" v-else>未付款</el-tag>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column label="是否发货" prop="is_send"></el-table-column>-->
            <el-table-column label="订单来源" prop="source" align="center">
                <template slot-scope="scope">
                    <el-tag type="primary" v-if="scope.row.source === 0">
                        小程序人工
                    </el-tag>
                    <el-tag type="danger" v-else-if="scope.row.source === 1">天猫人工</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.source === 2">天猫</el-tag>
                    <el-tag type="warning" v-else-if="scope.row.source === 3">抖音人工</el-tag>
                    <el-tag type="Info" v-else>未知</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="交易状态(天猫)" prop="source" align="center">
                <template slot-scope="scope" v-if="scope.row.source === 2">
                    <span v-if="scope.row.tmall_status ==='TRADE_NO_CREATE_PAY'">没有创建支付宝交易</span>
                    <span v-else-if="scope.row.tmall_status ==='WAIT_BUYER_PAY'">等待买家付款</span>
                    <span v-else-if="scope.row.tmall_status ==='SELLER_CONSIGNED_PART'">卖家部分发货</span>
                    <span v-else-if="scope.row.tmall_status ==='WAIT_SELLER_SEND_GOODS'">等待卖家发货,即:买家已付款</span>
                    <span v-else-if="scope.row.tmall_status ==='WAIT_BUYER_CONFIRM_GOODS'">等待买家确认收货,即:卖家已发货</span>
                    <span v-else-if="scope.row.tmall_status ==='TRADE_BUYER_SIGNED'">买家已签收,货到付款专用</span>
                    <span v-else-if="scope.row.tmall_status ==='TRADE_FINISHED'">交易成功</span>
                    <span v-else-if="scope.row.tmall_status ==='TRADE_CLOSED'">付款以后用户退款成功，交易自动关闭</span>
                    <span v-else-if="scope.row.tmall_status ==='TRADE_CLOSED_BY_TAOBAO'">付款以前，卖家或买家主动关闭交易</span>
                    <span v-else-if="scope.row.tmall_status ==='PAY_PENDING'">国际信用卡支付付款确认中</span>
                    <span v-else-if="scope.row.tmall_status ==='WAIT_PRE_AUTH_CONFIRM'">0元购合约中</span>
                    <span v-else-if="scope.row.tmall_status ==='PAID_FORBID_CONSIGN'">拼团中订单或者发货强管控的订单，已付款但禁止发货</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template>
                    <el-button icon="el-icon-edit" size="mini" type="primary" @click="showBox"></el-button>
                    <el-button icon="el-icon-location" size="mini" type="success" @click="showProgressBox"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="queryInfo.page" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pagesize"
                       layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <!-- 修改修改地址的对话框 -->
        <el-dialog title="修改地址" :visible.sync="addressVisible" width="50%" @close="addressDialogClosed">
            <!-- 内容主体区域 -->
            <el-form :model="addressForm" :rules="addressFormRules" ref="addressFormRef" label-width="100px">
                <el-form-item label="省市区/县" prop="address1">
                    <el-cascader :options="cityData" v-model="addressForm.address1"></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" prop="address2">
                    <el-input v-model="addressForm.address2"></el-input>
                </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="addressVisible = false">取 消</el-button>
                <el-button type="primary" @click="addressVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 展示物流进度的对话框 -->
        <el-dialog title="物流进度" :visible.sync="progressVisible" width="50%">
            <!-- 内容主体区域 Timeline时间线 -->
            <el-timeline>
                <el-timeline-item v-for="(activity, index) in progressInfo" :key="index" :timestamp="activity.time">
                    {{ activity.context }}
                </el-timeline-item>
            </el-timeline>
        </el-dialog>
    </div>
</template>

<script>
    import cityData from '../assets/js/cityData.js';
    import db from '../assets/js/db.js';
    export default {
        name: "Orders",
        data() {
            return {
                queryInfo: {
                    query: '',
                    page: 1,
                    pagesize: 10
                },
                total: 1,
                orderList: [],
                addressVisible: false,
                addressForm:{
                    address1:[],
                    address1:''
                },
                addressFormRules:{
                    address1:[{required:true,message:'请选择省市区县',trigger:'blur'}],
                    address1:[{required:true,message:'请填写详细地址',trigger:'blur'}]
                },
                cityData:cityData,
                progressVisible: false,
                progressInfo:[],
                db:db,
                imgHost:'https://cdn.naoled.cn/'
            }
        },
        created() {
            this.getOrderList()
        },
        methods: {
            async getOrderList() {
                const {data: result} = await this.$http.get('order_new', {
                    params: this.queryInfo
                });
                if (result.status !== 0) {
                    return this.$message.error('获取订单列表失败');
                }
                console.log(result);
                // this.total = result.total;
                this.orderList = result.result.data;
                for (let i in this.orderList) {
                    for(let j in this.orderList[i].orders)
                        if (!this.orderList[i].orders[j].goods.thumb.includes(this.imgHost)) {
                            this.orderList[i].orders[j].goods.thumb=this.imgHost+this.orderList[i].orders[j].goods.thumb;
                        }
                }
            },
            handleSizeChange(newSize) {
                this.queryInfo.pagesize = newSize;
                this.getOrderList();
            },
            handleCurrentChange(newPage) {
                this.queryInfo.page = newPage;
                this.getOrderList();
            },
            // 展示修改地址的对话框
            showBox() {
                this.addressVisible = true;
                console.log(cityData)
            },
            addressDialogClosed() {
                this.$refs.addressFormRef.resetFields();
            },
            showProgressBox() {
                this.progressInfo = this.db;
                this.progressVisible = true;
                console.log(this.progressInfo);
            },
            createOrders(){
                //指定跳转的地址
                this.$router.replace('/createOrders');
            }
        },
        computed:{

        }

    }
</script>

<style scoped>
    .bdtop {
        border-top: 1px solid #eee;
    }
    .bdbottom {
        border-bottom: 1px solid #eee;
    }
    .img_goods{
        width: 90%;
    }
    .title_goods{
        color: #409EFF;
        padding: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 40px;
    }
</style>