import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../components/Login.vue';
import Home from '../components/Home.vue';
import Welcome from '../components/Welcome.vue';
import Users from '../components/Users.vue';
import Rights from '../components/power/Rights.vue';
import Roles from '../components/power/Roles.vue';
import Orders from '../components/Orders.vue';
import OrderNew from '../components/OrderNew.vue';
import CreateOrders from '../components/CreateOrders.vue';
import Reservation from '../components/external/Reservation.vue';
import CreateGoods from '../components/CreateGoods.vue';
import Goods from '../components/Goods.vue';
import ReservationList from '../components/ReservationList.vue';
import ConsumeList from '../components/ConsumeList.vue';
import Map from '../components/external/Map.vue';
import Search from '../components/external/Search.vue';
import Test from '../components/external/Test.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/home',
        component: Home,
        redirect: '/welcome',
        children: [
            {path: '/welcome', component: Welcome},
            {path: '/users', component: Users},
            {path: '/rights', component: Rights},
            {path: '/roles', component: Roles},
            {path: '/orders', component: Orders},
            {path: '/createOrders', component: CreateOrders},
            {path: '/goods', component: Goods},
            {path: '/createGoods', component: CreateGoods},
            {path: '/orderNew', component: OrderNew},
            {path: '/reservationList', component: ReservationList},
            {path: '/consumeList', component: ConsumeList}
        ]
    },
    {
        path: '/reservation',
        component: Reservation
    },
    {
        path: '/map',
        component: Map
    },
    {
        path: '/test',
        component: Test
    },
    {
        path: '/search',
        component: Search
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    // to 将要访问的路径
    // from 代表从哪个路径跳转而来
    // next 是一个函数， 表示放行   next()放行   next('/login')强制跳转
    if (to.path === '/login') return next(); // 用户访问登录页，直接放行
    // 获取token
    // const tokenStr = window.sessionStorage.getItem('token')
    // if (!tokenStr) return next('/login'); // 没有token， 强制跳转到登录页
    next() // 否则(有token)直接放行
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
};

export default router
