<template>
    <div>
        <!-- 面包屑导航区域 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/orders' }">订单列表</el-breadcrumb-item>
            <el-breadcrumb-item>新建订单</el-breadcrumb-item>
        </el-breadcrumb>
        <!--        卡片视图-->
        <el-card>
            <el-col :span="12">
                <el-form :model="goods" :rules="goodsRules" ref="goodsFormRef" label-width="80px">
                    <el-form-item label="商品ID" prop="itemId">
                        <el-input v-model="goods.itemId" :span="6" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="商品名称" prop="itemTitle">
                        <el-input v-model="goods.itemTitle" :span="6" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="门店佣金" prop="commission">
                        <el-input v-model="goods.commission" :span="6" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="商品描述" prop="skuProperties">
                        <el-input v-model="goods.skuProperties" :span="6" clearable></el-input>
                    </el-form-item>
                </el-form>
                <el-col :span="24" align="center" style="margin:30px auto;">
                    <el-button type="primary" @click="submit('goodsFormRef')">提交</el-button>
                </el-col>
            </el-col>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "CreateOrder",
        data() {
            return{
                goods:{
                    itemId:'',
                    itemTitle:'',
                    skuProperties:'',
                    commission:''
                },
                //商品数据
                goodsRules:{
                    itemId:[
                        {required: true, message: '请输入商品Id', trigger: 'blur'},
                        { pattern: /^[^\u4e00-\u9fa5]+$/, message: '交易订单编号不允许输入中文',trigger: 'blur'},
                        { pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '交易订单编号不允许输入空格等特殊符号',trigger: 'blur'},
                        { pattern: /[0-9a-zA-Z]{1,6}/, message: '交易订单编号只可以输入数字和字母',trigger: 'blur'},
                        {min: 1, max: 20, message: '长度在 1 到 50 个字符', trigger: 'blur'},
                    ],
                    itemTitle:[
                        {required: true, message: '请输入商品名称', trigger: 'blur'},
                        {min: 3, max: 20, message: '长度在 3 到 50 个字符', trigger: 'blur'},
                    ],
                    commission:[
                        { required: true, message: '请输入门店佣金', trigger: 'blur' },
                        { pattern: /^(([1-9]\d*)|0)(\.\d{1,2})?$/, message: '请正确填写门店佣金',trigger: 'blur'},
                        { min: 1, max: 20, message: '门店佣金长度在 1 到 20个字符', trigger: 'blur' }
                    ],
                    skuProperties:[
                        { required: true, message: '请输入商品描述', trigger: 'blur' },
                        { min: 1, max: 50, message: '商品描述长度在 1 到 50个字符', trigger: 'blur' }
                    ]
                },
            }
        },
        methods:{
            //提交订单数据
            submit(formName){
                this.$refs[formName].validate(async(valid) => {
                    if (valid) {
                        //验证成功，发起新建订单的网络请求
                        const { data: res } = await this.$http.post('item', {itemId:this.goods.itemId,itemTitle:this.goods.itemTitle,skuProperties:this.goods.skuProperties,commission:this.goods.commission});
                        if (res.status == 0) {
                            this.$message.success('新建商品成功');
                            this.$router.push('/goods');
                        }else{
                            this.$message.error(res.message);
                        }
                    } else {
                        this.$message.error('新建商品失败');
                        return false;
                    }
                });
            }
        },
        created() {

        },
    }
</script>

<style scoped>
    .flex_align_center{
        display: flex;
        align-items: center;
    }
</style>