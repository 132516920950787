<template>
    <div>
        <!-- 面包屑导航区域 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 卡片视图区域 -->
        <el-card>
            <el-row :gutter="30">
                <el-col :span="9">
                    <el-input v-model="queryInfo.outerId" placeholder="请输入订单号">
                        <el-button slot="append" icon="el-icon-search" @click="serach()"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="createOrders">新建订单</el-button>
                </el-col>
            </el-row>
        </el-card>
        <!-- 订单列表数据 -->
        <el-table :data="orderList" style="width: 100%;padding:24px;" stripe>
            <el-table-column label="ID" prop="id" align="center">
            </el-table-column>
            <el-table-column label="订单号" prop="outerId" align="center">
            </el-table-column>
            <el-table-column label="商品【规格】" v-html align="center">
                <template slot-scope="scope">
                    <span v-html="scope.row.itemTitle"></span>
                    <div>【{{scope.row.skuProperties}}】</div>
                </template>
            </el-table-column>
            <el-table-column label="数量" prop="amount" align="center">
            </el-table-column>
            <el-table-column label="二维码信息" align="center">
                <template slot-scope="scope">
                    二维码值：
                    <div style="margin-bottom: 5px;">【{{scope.row.code}}】</div>
                    有效期：
                    <div>【{{scope.row.validStart}}】</div>-<div>【{{scope.row.validEnd}}】</div>
                </template>
            </el-table-column>
            <el-table-column label="买家联系方式" prop="mobile" align="center">
            </el-table-column>
            <el-table-column label="订单状态" prop="status" align="center">
                <template slot-scope="scope" >
                    <el-tag type="warning" style="margin-right: 5px;" v-if="scope.row.reservation[0] && scope.row.reservation[0].status === 0">待接单</el-tag>
                    <el-tag type="primary" style="margin-right: 5px;" v-else-if="scope.row.reservation[0] && scope.row.reservation[0].status === 1">已接单</el-tag>
                    <el-tag type="danger" style="margin-right: 5px;" v-else-if="scope.row.reservation[0] && scope.row.reservation[0].status === 2">已拒绝</el-tag>
                    <el-tag type="info" style="margin-right: 5px;" v-else>待预约</el-tag>
                    <el-tag type="warning" v-if="scope.row.status === 0">待核销</el-tag>
                    <el-tag type="primary" v-else-if="scope.row.status === 1">已核销</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.status === 2">已退款</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="订单操作信息" align="center">
                <template slot-scope="scope">
                    <span>预约时间：</span>
                    <div v-if="scope.row.reservation" style="margin-bottom: 5px;">
                        <div v-if="scope.row.reservation[0]">【{{scope.row.reservation[0].expect_time}}】</div>
                        <div v-else>【无】</div>
                    </div>
                    <div v-else>【无】</div>
                    <span>核销时间：</span>
                    <div v-if="scope.row.consume" style="margin-bottom: 5px;">
                        <div v-if="scope.row.consume.consume_time">【{{scope.row.consume.consume_time}}】</div>
                        <div v-else>【无】</div>
                    </div>
                    <div v-else>【无】</div>
                    <span>订单佣金：</span>
                    <div v-if="scope.row.consume" style="margin-bottom: 5px;">
                        <div v-if="scope.row.consume.commission">￥【{{scope.row.consume.commission}}】</div>
                        <div v-else>【0】</div>
                    </div>
                    <div v-else>【0】</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope" >
                    <el-button icon="el-icon-edit" size="mini" type="primary" @click="showOrderDialog(scope.$index)">编辑</el-button>
<!--                    <el-button icon="el-icon-phone" size="mini" type="warning" style="margin-top: 10px;" @click="showReservationDialog(scope.$index,scope.row.reservation)">预约</el-button>-->
<!--                    <el-button icon="el-icon-success" size="mini" type="success" style="margin-top: 10px;" @click="showConsumeDialog(scope.$index,scope.row.consume.merchid)">核销</el-button>-->
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="queryInfo.page" :page-size="queryInfo.pagesize"
                       layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
<!--        &lt;!&ndash; 修改地址的对话框 &ndash;&gt;-->
<!--        <el-dialog title="预约单" :visible.sync="reservationVisible" width="50%" @close="reservationDialogClosed">-->
<!--            &lt;!&ndash; 内容主体区域 &ndash;&gt;-->
<!--            <el-form :model="reservation" ref="reservationFormRef" label-width="150px">-->
<!--                <el-form-item label="预约安装时间:" prop="expect_time">-->
<!--                    {{reservation.expect_time}}-->
<!--                </el-form-item>-->
<!--                <el-form-item v-if="reservation.status==1&&reservation.merch_time" label="门店接单时间:" prop="expect_time">-->
<!--                    {{reservation.merch_time}}-->
<!--                </el-form-item>-->
<!--                <el-form-item v-if="reservation.status==1&&reservation.superior_time" label="服务商接单时间:" prop="expect_time">-->
<!--                    {{reservation.superior_time}}-->
<!--                </el-form-item>-->
<!--                <el-form-item label="安装区域：" class="region" label-width="150px">-->
<!--                    <el-col :span="24">-->
<!--                        <el-cascader :options="addressData" :disabled="false" @change="handleAddress" size="mini"-->
<!--                                     v-model="addressSelections" placeholder="请选择省市区" ref="cascader" popper-class="myascader"></el-cascader>-->
<!--                    </el-col>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="安装门店：" label-width="150px">-->
<!--                    <el-col :span="24" align="left" style="margin-bottom: 10px;">-->
<!--                        <el-button @click="getMerchList('',chooseMerch.province,chooseMerch.city,chooseMerch.area)"  type="primary" size="small">选择门店</el-button>-->
<!--                        <div v-if="total1=='0' && chooseMerch.province" style="color:red;font-size: .5rem;">*当前区域无可选门店，建议更换区域</div>-->
<!--                    </el-col>-->
<!--                    <div>-->
<!--                        <el-descriptions direction="vertical" :column="2" border>-->
<!--                            <el-descriptions-item label="门店">{{chooseMerch.merchname}}</el-descriptions-item>-->
<!--                            <el-descriptions-item label="电话">{{chooseMerch.contact_phone}}</el-descriptions-item>-->
<!--                            <el-descriptions-item label="地址">{{chooseMerch.address}}</el-descriptions-item>-->
<!--                        </el-descriptions>-->
<!--                    </div>-->
<!--                </el-form-item>-->
<!--            </el-form>-->
<!--            &lt;!&ndash; 底部区域 &ndash;&gt;-->
<!--            <span slot="footer" class="dialog-footer">-->
<!--                <el-button @click="addressVisible = false">取 消</el-button>-->
<!--                <el-button type="primary" @click="addressVisible = false">确 定</el-button>-->
<!--            </span>-->
<!--        </el-dialog>-->
<!--        &lt;!&ndash; 选择门店的对话框 &ndash;&gt;-->
<!--        <el-dialog title="预约门店" :visible.sync="addMerchDialogVisible" width="50%" @close="setMerchDialogClosed">-->
<!--            <el-table :data="tableData" border style="margin: 5px auto;" max-height="400">-->
<!--                &lt;!&ndash;                <el-table-column prop="logo" label="门店" align="center">&ndash;&gt;-->
<!--                &lt;!&ndash;                    <template slot-scope="scope">&ndash;&gt;-->
<!--                &lt;!&ndash;                        <el-image style="width: 80px; height: 80px;" :src="scope.row.logo" fit="cover" ></el-image>&ndash;&gt;-->
<!--                &lt;!&ndash;                        <div>{{scope.row.merchname}}</div>&ndash;&gt;-->
<!--                &lt;!&ndash;                    </template>&ndash;&gt;-->
<!--                &lt;!&ndash;                </el-table-column>&ndash;&gt;-->
<!--                <el-table-column prop="logo" label="安装门店" align="left">-->
<!--                    <template slot-scope="scope">-->
<!--                        <div style="font-size: .9rem;font-weight:600;">{{scope.row.merchname}}</div>-->
<!--                        <div style="color:#888;font-size: .7rem;">{{scope.row.address}}</div>-->
<!--                        <div style="color: #409EFF;font-size: .7rem;">{{scope.row.contact_phone}}</div>-->
<!--                    </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column label="操作" align="center" min-width="35%">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-button type="primary" size="mini" @click="setMerchChoose(scope.row.id,scope.row.merchname,scope.row.contact_phone,scope.row.address)">选择</el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
<!--            </el-table>-->
<!--            &lt;!&ndash;            <el-pagination @size-change="sizeChange" @current-change="currentChange"&ndash;&gt;-->
<!--            &lt;!&ndash;                           :current-page="page" :page-sizes="pageSizes" :pager-count="2"&ndash;&gt;-->
<!--            &lt;!&ndash;                           layout="total, prev, pager, next, jumper" :total="total">&ndash;&gt;-->
<!--            &lt;!&ndash;            </el-pagination>&ndash;&gt;-->
<!--        </el-dialog>-->
    </div>
</template>

<script>
    import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data';
    export default {
        name: "OrderNew",
        data() {
            return {
                queryInfo: {
                    query: '',
                    page: 1,
                    pagesize: 10,
                    outerId:''
                },
                //地址信息
                addressData: regionData,
                addressSelections: [],
                //dialog门店表格数据（显示数组）
                tableData:[],
                //第几页
                page: 1,
                //一页多少条
                size: 10000,
                //总条目数
                total1: 0,
                //可选择的一页多少条
                pageSizes: [],
                total: 1,
                orderList: [],
                orderVisible: false,
                // reservationVisible: true,
                consumeVisible: false,
                imgHost:'https://cdn.naoled.cn/',
                reservation:[],
                consume:[],
                chooseMerch:{
                    //选中的门店名
                    merchname:'',
                    //选中的门店ID
                    merchid:'',
                    //选中的门店联系方式
                    contact_phone:'',
                    //选中的门店地址
                    address:'',
                    //选中省市区
                    province:'',
                    city:'',
                    area:''
                },
                //门店数据
                merchData:[],
                //控制门店dialog显示
                // addMerchDialogVisible:false,
            }
        },
        created() {
            this.getOrderList();
        },
        methods: {
            async getOrderList() {
                const {data: result} = await this.$http.get('order_new', {
                    params: this.queryInfo
                }).catch((fail)=>{
                    return this.$message.error(fail.response.data.message);
                });
                if (result.status !== 0) {
                    return this.$message.error('获取订单列表失败');
                }
                this.total=result.result.total;
                this.orderList = result.result.data;
                this.queryInfo.pagesize=result.result.per_page;
            },
            handleSizeChange(newSize) {
                this.queryInfo.pagesize = newSize;
                this.getOrderList();
            },
            handleCurrentChange(newPage) {
                this.queryInfo.page = newPage;
                this.getOrderList();
            },
            // 展示修改订单的对话框
            showOrderDialog() {
                this.orderVisible = true;
            },
            orderDialogClosed() {
                this.orderVisible = false;
            },
            // 展示修改预约单的对话框
            showReservationDialog(index,row) {
                if(row.length>0){
                    this.getMerchList(row[0].merchid)
                    this.reservation=this.orderList[index].reservation[0];
                    this.reservationVisible = true;
                    console.log(this.reservation)
                }else{
                    return this.$message.error('该订单未预约');
                }
            },
            reservationDialogClosed() {
                this.reservationVisible = false;
            },
            // 展示核销单的对话框
            showConsumeDialog() {
                this.consumeVisible = true;
            },
            createOrders(){
                //指定跳转的地址
                this.$router.replace('/createOrders');
            },
            //省市区选择器选择
            handleAddress() {
                let deliveryAreaName=this.$refs["cascader"].getCheckedNodes()[0].pathLabels.join(',');
                deliveryAreaName=deliveryAreaName.split(',');
                this.chooseMerch.province=deliveryAreaName[0];
                this.chooseMerch.city=deliveryAreaName[1];
                this.chooseMerch.area=deliveryAreaName[2];
                this.getMerchList('',deliveryAreaName[0],deliveryAreaName[1],deliveryAreaName[2]);
                console.log(deliveryAreaName)
            },
            //获取门店列表
            async getMerchList(id,province,city,area) {
                if(id){
                    const {data: result} = await this.$http.get('ewei_shop_merch_tmall/'+id, {
                    }).catch((fail)=>{
                        return this.$message.error(fail.response.data.message);
                    });
                    if (result.status !== 0) {
                        return this.$message.error('获取门店列表失败');
                    }
                    this.chooseMerch=result.result;
                }else{
                    if(province){
                        const {data: result} = await this.$http.get('ewei_shop_merch_tmall?&province='+province+'&city='+city+'&area='+area, {
                        }).catch((fail)=>{
                            return this.$message.error(fail.response.data.message);
                        });
                        if (result.status !== 0) {
                            return this.$message.error('获取门店列表失败');
                        }
                        this.merchData=result.result;
                        this.addMerchDialogVisible=true;
                    }else{
                        return this.$message.error('请先选择安装区域');
                    }
                }
            },
            //查找订单
            async serach(){
                this.getOrderList();
            },
            //关闭选择门店dialog时执行
            setMerchDialogClosed(){
                this.addMerchDialogVisible = false;
                this.page = 1;
            },
            //选中的门店数据
            setMerchChoose(id,merchname,contact_phone,address){
                this.chooseMerch.merchid=id;
                this.chooseMerch.merchname=merchname;
                this.chooseMerch.contact_phone=contact_phone;
                this.chooseMerch.address=address;
                this.addMerchDialogVisible = false;
            }
        },
        computed:{

        }

    }
</script>

<style scoped>
    .bdtop {
        border-top: 1px solid #eee;
    }
    .bdbottom {
        border-bottom: 1px solid #eee;
    }
    .img_goods{
        width: 90%;
    }
    .title_goods{
        color: #409EFF;
        padding: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 40px;
    }
</style>