<template>
    <el-container class="home-container">
        <!--        头部区域-->
        <!--        每一个element-UI中提供的组件名称就是类名，即el-header就是类名-->
        <el-header style="height:50px;">
            <!--            <div>-->
            <!--                <img src="../assets/logo.jpg" alt="">-->
            <!--                <span>九加一门店订单管理系统</span>-->
            <!--            </div>-->
            <el-button type="info" @click="goout">退出</el-button>
        </el-header>
        <!--        页面主体区域-->
        <el-container>
            <!--            侧边栏-->
            <!--            三元运算，当isCollapse 变成true，折叠起来的时候，侧边栏宽度变成60px-->
            <el-aside :width="isCollapse ? '60px':'200px'">
                <div class="toggle-button" @click="toggleCollapse"> |||</div>
                <!--                 侧边栏菜单区域-->
                <!--                 collapse	是否水平折叠收起菜单,默认是false，不折叠-->
                <!--                 collapse-transition 是否开启折叠动画，默认是true开启，要关闭-->
                <el-menu :collapse="isCollapse" :collapse-transition='false' unique-opened background-color="#333744"
                         text-color="#fff" active-text-color="#409EFF" :router="true" :default-active="activePath">
                    <!--                    一级菜单-->
                    <template v-for="item in menulist">
                        <el-menu-item :index="'/'+item.path" v-if="item.length <= 0" :key="item.id" @click="saveNavState('/'+ subItem.path)">
                            <!--                            图标-->
                            <img v-if="item.path === 'home'" class="shop_logo" src="../assets/logo.jpg" alt="">
                            <i v-else :class="item.icon"></i>
                            <!--                            文本-->
                            <span>{{item.authName}}</span>
                        </el-menu-item>
                        <el-submenu :index="item.id+''" v-else :key="item.id">
                            <!--                        一级菜单的模板区域-->
                            <template slot="title">
                                <!--                            图标-->
                                <i :class="item.icon"></i>
                                <!--                            文本-->
                                <span>{{item.authName}}</span>
                            </template>
                            <!--                        二级菜单-->
                            <el-menu-item :index="'/'+subItem.path" v-for="subItem in item.children" :key="subItem.id" @click="saveNavState('/'+ subItem.path)">
                                <template slot="title">
                                    <i :class="subItem.icon"></i>
                                    <span>{{subItem.authName}}</span>
                                </template>
                            </el-menu-item>
                        </el-submenu>
                    </template>
                </el-menu>
            </el-aside>
            <!--            右侧内容主体-->
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    export default {
        name: "Home",
        methods: {
            goout() {
                window.sessionStorage.clear();
                this.$router.push('/login')
            },
            toggleCollapse() {
                this.isCollapse = !this.isCollapse //取反
            },
            // 保存链接的激活状态
            saveNavState(activePath) {
                window.sessionStorage.setItem('activePath', activePath)
                this.activePath = activePath
            }
            //获取所有菜单
            // async getMenuList() {
            //     const { data: result } = await this.$http.get('menus');
            //     //如果获取数据失败，就弹出错误消息，并且错误消息是保存在result.meta.msg里面的
            //     if (result.meta.status !== 200) return this.$message.error(result.meta.msg);
            //     //如果成功就赋值
            //     this.menulist = result.data;
            //     console.log(result)
            // }
        },
        data() {
            return {
                isCollapse: false, //不折叠
                // 被激活的链接地址
                activePath: '',
                //拿到数据之后，为了在页面中渲染出来，应该把获取到的数据立即挂载到data里面
                //左侧菜单数据
                menulist: [
                    {
                        authName: '门店订单管理系统',
                        children: [],
                        id: 1,
                        path: 'orderNew',
                        icon: 'el-icon-s-home',
                        length: 0
                    },
                    {
                        authName: '订单管理',
                        children: [
                            {id: 21, authName: '新建订单', path: 'createOrders', children: [], icon: 'el-icon-s-order'},
                            {id: 22, authName: '天猫订单', path: 'orderNew', children: [], icon: 'el-icon-s-order'},
                            {id: 23, authName: '预约单', path: 'reservationList', children: [], icon: 'el-icon-s-order'},
                            {id: 24, authName: '核销单', path: 'consumeList', children: [], icon: 'el-icon-s-order'},
                            // {id: 25, authName: '售后管理', path: 'afterSales', children: [], icon: 'el-icon-s-order'}
                        ],
                        id: 2,
                        path: 'orders',
                        icon: 'el-icon-s-order',
                        length: 3
                    },
                    {
                        authName: '商品管理',
                        children: [
                            {id: 31, authName: '新建商品', path: 'createGoods', children: [], icon: 'el-icon-s-goods'},
                            {id: 32, authName: '商品列表', path: 'goods', children: [], icon: 'el-icon-s-goods'}
                        ],
                        id: 3,
                        path: 'goods',
                        icon: 'el-icon-s-goods',
                        length: 2
                    },
                    // {
                    //     authName: '门店管理',
                    //     children: [],
                    //     id: 4,
                    //     path: 'merch',
                    //     icon: 'el-icon-s-shop',
                    //     length: 0
                    // },
                    // {
                    //     authName: '财务管理',
                    //     children: [],
                    //     id: 5,
                    //     path: 'finance',
                    //     icon: 'el-icon-s-finance',
                    //     length: 0
                    // },
                    {
                        authName: '人员管理',
                        children: [
                            {id: 61, authName: '用户列表', path: 'users', children: [], icon: 'el-icon-s-custom'},
                        ],
                        id: 6,
                        path: 'users',
                        icon: 'el-icon-s-custom',
                        length: 1
                    },
                    {
                        authName: '权限管理',
                        children: [
                            {id: 71, authName: '权限列表', path: 'rights', children: [], icon: 'el-icon-s-tools'},
                            {id: 72, authName: '角色列表', path: 'roles', children: [], icon: 'el-icon-s-tools'}
                        ],
                        id: 7,
                        path: 'rights',
                        icon: 'el-icon-s-tools',
                        length: 2
                    },
                    // {
                    //     authName: '系统管理',
                    //     children: [],
                    //     id: 8,
                    //     path: 'system',
                    //     icon: 'el-icon-s-platform',
                    //     length: 0
                    // }
                ],
            }
        },
        //页面加载之前就要获取数据，定义生命周期函数
        created() {
            // this.getMenuList();
            this.activePath = window.sessionStorage.getItem('activePath')
        }
    }
</script>

<style lang="less" scoped>
    .shop_logo {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .home-container {
        height: 100%;
    }

    .el-header {
        background-color: #373D41;
        display: flex;
        //左右贴边对齐
        justify-content: space-between;
        //上下居中
        align-items: center;

        //字体
        color: #fff;
        font-size: 20px;
        //嵌套子选择器
        > div {
            display: flex;
            align-items: center;

            span {
                margin-left: 15px;
            }
        }
    }

    .el-aside {
        background-color: #333744;
    }

    .el-main {
        background-color: #eaedf1;
    }

    .el-menu {
        border-right: none;
    }

    .toggle-button {
        background-color: #4A5064;
        font-size: 10px;
        line-height: 24px;
        color: #fff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }
</style>