import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/global.css';
import axios from 'axios';
import {Message} from 'element-ui';
import { Container, Header, Aside, Main } from 'element-ui';
import { Breadcrumb,BreadcrumbItem } from 'element-ui';
import {Card,Row,Col } from 'element-ui';
import {Table,TableColumn } from 'element-ui';
import { Switch} from 'element-ui';
import { Tooltip } from 'element-ui';
import { Dialog } from 'element-ui';
import { MessageBox } from 'element-ui';
import { Tag } from 'element-ui';
import { Tree } from 'element-ui';
import { Select,Option } from 'element-ui';
import unit from '@/assets/js/unti.js';
import Vant from 'vant';
import 'vant/lib/index.css';
import maps from "qqmap";
import AMapLoader from "@amap/amap-jsapi-loader";
Vue.use(AMapLoader);
Vue.use(maps);

Vue.use(Select);
Vue.use(Option);
Vue.use(Tree);
Vue.use(Tag);
Vue.use(Dialog);
Vue.use(Tooltip);
Vue.use(Switch);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Card);
Vue.use(Row);
Vue.use(Col);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Vant);


// 配置请求的根路径
axios.defaults.timeout = 5000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';           //配置请求头
axios.defaults.baseURL = 'https://server.naoled.cn/';

axios.interceptors.request.use(config => {
    // 为请求头对象，添加token验证的 Authorization 字段
    config.headers.Authorization = window.sessionStorage.getItem('token');
    // 固定写法，最后必须return config
    return config;
});

Vue.prototype.$unit = unit;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$http = axios;
Vue.prototype.$message = Message; // 弹框提示组件挂在在Vue原型上

Vue.config.productionTip = false;
Vue.use(ElementUI);


new Vue({
    router,
    render: h => h(App)
}).$mount('#app');

