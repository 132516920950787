import AMapLoader from '@amap/amap-jsapi-loader'

/**
 * 高德地图统一配置,任何调用高德地图的方法都需要先加载配置成功后才能调用
 * @type {Promise<any>} 返回加载loader完成后Promise对象
 */
export const AMapBase = AMapLoader.load({
    key: '53dfa5272dfd8586ece62d354a8c58ec', // 申请好的Web端开发者Key，首次调用 load 时必填
    version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    AMapUI: { // 是否加载 AMapUI，缺省不加载
        version: '1.1', // AMapUI 缺省 1.1
        plugins: [] // 需要加载的 AMapUI ui插件
    }
})

/**
 *  * 添加标记方法
 * @param opts 其他标记配置(也可以传自定义数据用于click的callback传参) 详看 https://lbs.amap.com/api/javascript-api/reference/overlay#marker
 * @param click 点击回调事件
 * @param dragstart 拖拽开始回调事件
 * @param dragend 拖拽结束回调事件
 * @param dragging 拖拽时回调事件
 * @returns {AMap.Marker}
 */
export function setMarker (opts, click, dragstart, dragend, dragging) {
    var marker = new AMap.Marker({
        position: new AMap.LngLat(opts.lnglat[0], opts.lnglat[1]), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9],opts.lnglat只记录最初定位后续定位通过方法监听
        draggable: dragstart || dragend || dragging,
        cursor: 'move',
        ...opts
    })
    click && marker.on('click', () => click(opts, marker))
    dragstart && marker.on('dragstart', () => dragstart(opts, marker))
    dragend && marker.on('dragend', () => dragend(opts, marker))
    dragging && marker.on('dragging', () => dragging(opts, marker))

    return marker
}

/**
 * 获取定位方法
 * @param map 创建地图后的对象
 * @param opts 定位插件相关配置 详看  https://lbs.amap.com/api/javascript-api/reference/location#m_AMap.Geolocation
 * @returns {Promise<unknown>}
 */
export function mapGeolocation (map, opts = {}) {
    return new Promise((resolve, reject) => {
        AMap.plugin('AMap.Geolocation', function () {
            // 定位插件相关配置
            var geolocation = new AMap.Geolocation({
                // 是否使用高精度定位，默认：true
                enableHighAccuracy: true,
                // 设置定位超时时间，默认：无穷大
                timeout: 10000,
                //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                zoomToAccuracy: true,
                // 其他定位配置
                ...opts
            })
            geolocation.on('click', () => console.log('我看看'))
            // geolocation.watchPosition()
            map.addControl(geolocation);
            // 地图回调方法
            geolocation.getCurrentPosition(function (status, result) {
                if (status === 'complete') {
                    resolve(result)
                } else {
                    reject(result)
                }
            })
        })
    })
}

/**
 * 获取所在城市方法
 * @param map 创建地图后的对象
 * @param opts 定位插件相关配置 详看  https://lbs.amap.com/api/javascript-api/reference/location#m_AMap.Geolocation
 * @returns {Promise<unknown>}
 */
export function mapCitySearch () {
    return new Promise((resolve, reject) => {
        AMap.plugin('AMap.CitySearch', function () {
            var citySearch = new AMap.CitySearch();
            citySearch.getLocalCity(function (status, result) {
                if (status === 'complete' && result.info === 'OK') {
                    resolve(result)
                    // 查询成功，result即为当前所在城市信息
                }else{
                    reject(result)
                }
            })
        });
    })
}


/**
 * 逆向地理编码方法
 * @param lnglat [经度,纬度]
 * @returns {Promise<unknown>}
 */
export function mapGetAddress (lnglat) {
    return new Promise((resolve, reject) => {
        AMap.plugin('AMap.Geocoder', function () {
            const geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                // city: '010'
            })
            geocoder.getAddress(lnglat, function (status, result) {
                if (status === 'complete' && result.info === 'OK') {
                    resolve(result)
                } else {
                    reject(result)
                }
            })
        })
    })
}

/**
 * 设置标记点击弹框方法
 * @param offsetType 弹出方向 top,right,bottom,left
 * @param content 弹框内容,字符串dom
 * @returns {AMap.InfoWindow}
 */
export function setInfoWindow (offsetType, content) {
    let offset, anchor
    switch (offsetType) {
        case 'top':
            offset = new AMap.Pixel(0, -36)
            anchor = 'bottom-center'
            break
        case 'right':
            offset = new AMap.Pixel(10, -15)
            anchor = 'middle-left'
            break
        case 'bottom':
            offset = new AMap.Pixel(0, 5)
            anchor = 'top-center'
            break
        case 'left':
            offset = new AMap.Pixel(-10, -15)
            anchor = 'middle-right'
            break
        default:
            offset = new AMap.Pixel(0, -36)
            anchor = 'bottom-center'
            break
    }
    return new AMap.InfoWindow({ offset, content, anchor, isCutom: true, autoMove: true })
}

/**
 * 检测app是否安装,安装直接打开app,否则跳转
 * @param androidUri 安卓调起地址
 * @param iosUri ios调起地址
 * @param h5Uri h5调起地址
 */
function clickDownload (androidUri, iosUri, h5Uri) {
    if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        try {
            var loadDateTime = new Date()
            iosUri ? window.location = iosUri : window.location = androidUri// schema链接或者universal link
            window.setTimeout(function () { // 如果没有安装app,便会执行setTimeout跳转下载页
                var timeOutDateTime = new Date()
                if (timeOutDateTime - loadDateTime < 5000) {
                    window.open(h5Uri, 'newWindow') // 打开新的页面
                } else {
                    window.close()
                }
            }, 2000)
        } catch (e) {
            alert(e)
        }
    } else if (navigator.userAgent.match(/android/i)) {
        try {
            window.location = androidUri // schema链接或者universal link
            window.setTimeout(function () {
                window.open(h5Uri, 'newWindow') // 打开新的页面
            }, 2000)
        } catch (e) {
            alert(e)
        }
    }
}

/**
 * H5调取导航方法
 * @param nType 导航类型 TX:腾讯 , BD:百度 ,GD:高德
 * @param data 导航参数
 */
export function openNavigationApp (nType = 'GD', data) {
    let androidUri = null, iosUri = null, h5Uri = null;
    switch (nType) {
        // 打开腾讯导航
        // h5详见:https://lbs.qq.com/webApi/uriV1/uriGuide/uriWebRoute
        // android/ios详见:https://lbs.qq.com/webApi/uriV1/uriGuide/uriMobileRoute
        case 'TX':
            androidUri = `qqmap://map/routeplan?type=drive&referer=kpweizhi&from=%E6%88%91%E7%9A%84%E4%BD%8D%E7%BD%AE&fromcoord=CurrentLocation&to=${data.title}&tocoord=${data.lnglat[1]},${data.lnglat[0]}`
            h5Uri = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${data.lnglat[1]},${data.lnglat[0]};title:${data.title};addr:${data.title}&referer=myapp`
            console.log('打开腾讯导航页面')
            break
        // 打开百度导航
        // h5详见:http://lbsyun.baidu.com/index.php?title=uri/api/web#service-page-anchor2
        // android/ios详见:https://lbsyun.baidu.com/index.php?title=uri/api/android
        case 'BD':
            androidUri = `baidumap://map/marker?location=${data.lnglat[1]},${data.lnglat[0]}&title=${data.title}&zoom=18&coord_type=bd09mc&src=webapp.default.all.callnaopen_safari`
            iosUri = `baidumap://map/marker?location=${data.lnglat[1]},${data.lnglat[0]}&title=${data.title}&content=${data.title}&src=ios.baidu.openAPIdemo`
            h5Uri = `https://api.map.baidu.com/marker?location=${data.lnglat[1]},${data.lnglat[0]}&title=${data.title}&content=${data.title}&output=html&src=webapp.baidu.openAPIdemo`
            console.log('打开百度导航')
            break
        // 打开高德导航
        // h5详见:https://lbs.amap.com/api/uri-api/gettingstarted
        // android/ios详见:https://lbs.amap.com/api/amap-mobile/guide/android/route/
        case 'GD':
            androidUri = `amapuri://route?sourceApplication=mo&slat=&slon=&sname=&dlat=${data.lnglat[1]}&dlon=${data.lnglat[0]}&dname=${data.title}&dev=0&m=0&t=2`
            iosUri = `iosamap://viewMap?sourceApplication=一路顺风网络货运&poiname=${data.title}&lat=${data.lnglat[1]}&lon=${data.lnglat[0]}&dev=1&style=2`
            h5Uri = `https://uri.amap.com/marker?callnative=0&position=${data.lnglat[0]},${data.lnglat[1]}&name=${data.title}`
            console.log('打开高德页面')
            break
        case 'PG':
            iosUri = `http://maps.apple.com/?daddr=${data.title}&sll=${data.lnglat[0]},${data.lnglat[1]}&z=10&t=s`
            h5Uri = `http://maps.apple.com/?daddr=${data.title}&sll=${data.lnglat[0]},${data.lnglat[1]}&z=10&t=s`
            console.log('打开苹果页面')
            break
        default:
            break
    }
    clickDownload(androidUri, iosUri, h5Uri)
}
